import { ID } from "../../../../../helpers";
import { API_URI } from "../../../../constants/api";
import Req from "../../../../utils/request";
import { Content } from "./_models";
import revalidateClientStaticPage from "../../../../utils/request/revalidateClientStaticPage";

const API_URL = process.env.REACT_APP_API_URL;

const getContentById = async (id: ID) => {
  try {
    const url = `${API_URL + API_URI.CONTENT + API_URI.PUBLIC}/${id}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getContent = async (query?: string) => {
  try {
    const url = `${API_URL + API_URI.CONTENT}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const deleteContent = async (contentId: ID) => {
  try {
    const url = `${API_URL + API_URI.CONTENT}/${contentId}`;
    const result = await Req.DELETE(url);
    if (result?.status === 200 && result?.data) {
      await revalidateClientStaticPage('/');
      await revalidateClientStaticPage(`/content/${result?.data.slug}`);
      return [false, result.data];
    }
    return [true, result.data];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const createContent = async (content: Content) => {
  try {
    const url = `${API_URL + API_URI.CONTENT}`;
    const result = await Req.POST(url, content);
    if (result?.status === 200 && result?.data) {
      await revalidateClientStaticPage('/');
      await  revalidateClientStaticPage(`/content/${result?.data.slug}`);
      return [false, result.data];
    }
    return [true, result.data];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};
const updateContent = async (content: Content) => {
  try {
    const url = `${API_URL + API_URI.CONTENT}/${content.id}`;
    const result = await Req.PUT(url, content);
    if (result?.status === 200 && result?.data) {
      await  revalidateClientStaticPage('/');
      await  revalidateClientStaticPage(`/content/${result?.data.slug}`);
      return [false, result.data];
    }
    return [true, result.data];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export { getContentById, getContent, deleteContent, createContent, updateContent };