import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { KTCard } from "helpers";
import { QueryResponseProvider } from "./core/QueryResopnseProvider";
import { ContentTable } from "./table/ContentTable";
import { ContentListHeader } from "./components/ContentListHeader";
import { ContentEditModal } from "./content-modal/ContentModal";

const ContentList = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <KTCard>
        <ContentListHeader />
        <ContentTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <ContentEditModal />}
    </>
  );
};

const ContentListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ContentList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { ContentListWrapper };
