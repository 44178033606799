import {useQuery} from 'react-query'
import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../core/ListViewProvider'
import { isNotEmpty, QUERIES } from 'helpers'
import { getUserById } from "../core/_requests";

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={isLoading} user={{id: undefined}} />
  }

  if (!isLoading && !error && data?.data) {
    // @ts-ignore
    return <UserEditModalForm isUserLoading={isLoading} user={data?.data} />
  }

  return null
}

export {UserEditModalFormWrapper}
