/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { defaultAccount, IAccount } from "./core/_model";
import styles from "./Account.module.scss";
import UploadAvatar from "./uploadAvatar";
import { useAuth } from "app/modules/auth";
import { updateAccount } from "./core/_request";
import { toastAlert } from "app/utils/request/toastify";
import { useQueryResponse } from "../../products/products-list/core/QueryResponseProvider";
import md5 from "md5";

const accountSchema = Yup.object()
  .shape({
    first_name: Yup.string()
      .required("First Name is required"),
    last_name: Yup.string()
      .required("Last Name is required"),
    email: Yup.string()
  });
const addAdminSchema = Yup.object()
  .shape({
    password_hash: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(16, "Maximum 16 symbols")
      .required("Password can is required"),
    confirm_password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(16, "Maximum 16 symbols")
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password_hash"), null], "Password Confirm must match Password")
  });

export function Account() {
  const {
    currentUser,
    logout
  } = useAuth();
  const { refetch } = useQueryResponse();
  const [itemIdForUpdate, setItemIdForUpdate] = useState();
  const [isPassword, setIsPassword] = useState(false);

  const [dataAccount] = useState< IAccount>({
    id: currentUser?.id || defaultAccount.id,
    avatar: currentUser?.avatar || defaultAccount.avatar,
    email: currentUser?.email || defaultAccount.email,
    first_name: currentUser?.first_name || defaultAccount.first_name,
    last_name: currentUser?.last_name || defaultAccount.last_name,
    confirm_password: currentUser?.confirm_password || defaultAccount.confirm_password,
    password_hash: currentUser?.password_hash || defaultAccount.password_hash,
    admin_id: currentUser?.id || defaultAccount.admin_id
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
      setItemIdForUpdate(undefined);
    }
  };

  const formik = useFormik<IAccount>({
    initialValues: dataAccount,
    validationSchema: !itemIdForUpdate ? accountSchema : addAdminSchema,
    onSubmit: async (value, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const [isError, data] = await updateAccount({
          ...value,
          password_hash: md5(value.password_hash as string),
          confirm_password: md5(value.confirm_password as string),
        }, isPassword);
        if (!isError && data) {
          toastAlert({
            type: "success",
            message: "Update Success!"
          });
          setItemIdForUpdate(undefined);
          refetch();
          if (value.password_hash && value.confirm_password) {
            setTimeout(() => logout(), 2500);
          }
        } else {
          toastAlert({
            type: "error",
            message: "Update Failed!"
          });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    }
  });

  return (
    <div className="card">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bolder mb-2 text-dark">Profile Details</span>
        </h3>
      </div>
      <div className="separator separator-solid"></div>
      <form className="form d-flex flex-center" onSubmit={formik.handleSubmit}>
        <div className="card-body mw-800px py-20">
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Avatar</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <UploadAvatar
                  image={formik.getFieldProps("avatar").value}
                  field="avatar"
                  setImage={formik.setFieldValue}
                />
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label htmlFor="full_name" className="col-lg-3 col-form-label required">Full Name</label>
            <div className="col-lg-9">
              <div className="d-flex">
                <div className="spinner spinner-sm spinner-primary spinner-right me-3 flex-grow-1">
                  <input
                    id="full_name"
                    autoComplete="off"
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    {...formik.getFieldProps("first_name")}
                    name="first_name"
                    placeholder="First name"
                  />
                </div>
                <div className="spinner spinner-sm spinner-primary spinner-right ms-3 flex-grow-1">
                  <input
                    id="full_name"
                    autoComplete="off"
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    placeholder="Last name"
                    {...formik.getFieldProps("last_name")}
                    name="last_name"
                  />
                </div>
              </div>
              <div className="form-text">
                <span className={styles.textError}>
                  {formik.errors.first_name || formik.errors.last_name}
                </span>
              </div>
            </div>
          </div>
          <div className="row mb-8">
            <label htmlFor="email" className="col-lg-3 col-form-label required">Email Address</label>
            <div className="col-lg-9">
              <div className="input-group input-group-lg input-group-solid">
                <span className="input-group-text pe-3">
                  <i className="la la-at fs-4"></i>
                </span>
                <input
                  id="email"
                  type="text"
                  className="form-control form-control-lg form-control-solid disabled"
                  placeholder="Email"
                  {...formik.getFieldProps("email")}
                  name="email"
                  readOnly
                  disabled
                />
              </div>
              <div className="form-text">
                {formik.errors.email ? (
                  <span className={styles.textError}>{formik.errors.email}</span>
                ) : (
                  <span>
                    Email will not be publicly displayed.
                    <a href="#" className="fw-bold">
                      Learn more
                    </a>
                    .
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="fv-row mb-7 d-flex justify-content-end">
              <h5>
                <a className="text-primary" href="#"
                   onClick={() => setIsPassword(!isPassword)} role="tab">
                  Set New Password?
                </a>
              </h5>

            </div>
            {isPassword &&
              <div className="row mb-8">
                <label htmlFor="password" className="col-lg-3 col-form-label">Password</label>
                <div className="col-lg-9">
                  <div className="d-flex">
                    <div className="spinner spinner-sm spinner-primary spinner-right me-3 flex-grow-1">
                      <input
                        id="password"
                        autoComplete="off"
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        {...formik.getFieldProps("password_hash") || ""}
                        name="password_hash"
                        placeholder="Password"
                      />
                    </div>
                    <div className="spinner spinner-sm spinner-primary spinner-right ms-3 flex-grow-1">
                      <input
                        autoComplete="off"
                        className="form-control form-control-lg form-control-solid"
                        type="password"
                        placeholder="Confirm Password"
                        {...formik.getFieldProps("confirm_password") || ""}
                        name="confirm_password"
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>

          <div className="separator separator-solid"></div>

          <div className="row">
            <div className="col-lg-9 mt-5 w-100 d-flex justify-content-end">
              <button type="reset" className="btn btn-light me-3">
                Cancel
              </button>
              <button type="submit" className="btn btn-primary fw-bolder px-6 py-3 me-3">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}