import { CustomHeader } from "app/layout/components/table/columns/CustomHeader";
import { DateCell } from "app/layout/components/table/columns/DateCell";
import { ProductActionsCell } from "app/layout/components/table/columns/ProductActionsCell";
import { ProductImageCell } from "app/layout/components/table/columns/ProductImageCell";

import { Column } from "react-table";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { deleteProduct } from "../../core/_requests";
import { toastAlert } from "../../../../../utils/request/toastify";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import ProductStatusCell from "../../../../../layout/components/table/columns/ProductStatusCell";
import { ID } from "../../../../../../helpers";
import { TextCell } from "../../../../../layout/components/table/columns/TextCell";
import CurrencyFormat from "react-currency-format";
import React from "react";

const onHandleDelete = async (productId: ID) => {
  const [error, data] = await deleteProduct(productId);
  if (!error && data) {
    toastAlert({
      type: "success",
      message: "Delete Success!"
    });
  } else {
    toastAlert({
      type: "error",
      message: "Delete Failed!"
    });
  }
};

const productsColumns: ReadonlyArray<Column<any>> = [
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Thumbnail"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "id",
    Cell: ({ ...props }) =>
      <ProductImageCell
        id={props.data[props.row.index].id}
        isDetail={true}
        product={props.data[props.row.index]?.thumbnail} />
  },
  {
    Header: (props: any) => (
      <CustomHeader
        tableProps={props}
        title="Product Name"
        className="text-left min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "product_name",
    Cell: ({ ...props }) => (
      <TextCell text={props.data[props.row.index]?.product_name} />
    )
  },
  {
    Header: (props: any) => (
      <CustomHeader
        tableProps={props}
        title="Price"
        className="text-left min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "price",
    Cell: ({ ...props }) => (
      <>
        <CurrencyFormat
          className={props.data[props.row.index].renewable_price ? "text-decoration-line-through"
            : ""}
          value={props.data[props.row.index].price}
          displayType={"text"}
          thousandSeparator={true}
          suffix={" "}
        />
        {
          props.data[props.row.index].renewable_price > 0 &&
          <div>
            <CurrencyFormat
              className="fs-4"
              value={props.data[props.row.index].renewable_price}
              displayType={"text"}
              thousandSeparator={true}
              suffix={" "}
            />
          </div>
        }
      </>
    )
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Create Date"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "created_date",
    Cell: ({ ...props }) =>
      <DateCell date={props.data[props.row.index]?.created_date} />
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Status"
        className="min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "status",
    Cell: ({ ...props }) =>
      <ProductStatusCell status={props.data[props.row.index]?.is_public ? "ACTIVE" : "INACTIVE"} />
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ProductActionsCell
        id={props.data[props.row.index].id}
        useListView={useListView}
        useQueryResponse={useQueryResponse}
        onHandleDelete={onHandleDelete}
      />
    )
  }
];

export { productsColumns };
