import { menuItems } from "app/constants/menu";
import { Route, Outlet, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../layout/core";
import { UsersListWrapper } from "./users-list/UsersList";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "User",
    path: menuItems.USER.route,
    isSeparator: false,
    isActive: false
  }
];

const UsersPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}></Route>
      <Route
        index
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Users Management</PageTitle>
            <UsersListWrapper />
          </>
        }
      />
    </Routes>

  );
};

export default UsersPage;
