import { Banner } from "../../../banner/banner-list/core/_models";
import { API_URI } from "../../../../constants/api";
import Req from "../../../../utils/request";
import revalidateClientStaticPage from "../../../../utils/request/revalidateClientStaticPage";
import { ID } from "../../../../../helpers";

const API_URL = process.env.REACT_APP_API_URL;

const createPartner = async (banner: Banner) => {
  try {
    const url = `${API_URL + API_URI.PARTNER}`;
    const result = await Req.POST(url, banner);
    if (result?.status === 200 && result?.data) {
    await  revalidateClientStaticPage("/");
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const updatePartner = async (banner: Banner) => {
  try {
    const url = `${API_URL + API_URI.PARTNER}/${banner.id}`;
    const result = await Req.PUT(url, banner);
    if (result?.status === 200 && result?.data) {
    await  revalidateClientStaticPage("/");
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getPagePartner = async (query?: string) => {
  try {
    const url = `${API_URL + API_URI.PARTNER + API_URI.PUBLIC}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getPartnerById = async (banner: ID) => {
  try {
    const url = `${API_URL + API_URI.PARTNER + API_URI.PUBLIC}/${banner}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};
const deletePartner = async (bannerId: ID) => {
  try {
    const url = `${API_URL + API_URI.PARTNER}/${bannerId}`;
    const result = await Req.DELETE(url);
    if (result?.status === 200 && result?.data) {
      await  revalidateClientStaticPage("/");
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export { createPartner, getPagePartner, getPartnerById, deletePartner, updatePartner };