import { ID } from "../../../../../helpers";

export interface Banner {
  id?: ID,
  image: string,
  link_url: string,
  status?: string,
  created_date?: string;
  show_in?: ShowIn;
}


export type ShowIn = "DESKTOP" | "MOBILE";

export const showInOptions = ["DESKTOP", "MOBILE"];
