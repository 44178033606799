import { Suspense, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProvider, LayoutSplashScreen } from "./layout/core";
import { MasterInit } from "./layout/MasterInit";

import { AuthInit } from "./modules/auth";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const theme = "dark";
  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty(
      "--mode",
      theme 
    );
  }, [theme]);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <AuthInit>
          <Outlet />
          <MasterInit />
        </AuthInit>
      </LayoutProvider>
    </Suspense>
  );
};

export { App };
