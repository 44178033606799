/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "helpers";
import { STATIC_IMAGE } from "app/constants/assets";
import { ForgotPasswordAdmin } from "./components/ForgotPasswordAdmin";

const AuthLayout = () => {

  useEffect(() => {
    document.body.classList.add("bg-white");
    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column bg-light flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/sketchy-1/14.png")})`
      }}>
      <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <a href="#" className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl(STATIC_IMAGE.LOGO)} className="h-45px" />
        </a>
        <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => (

  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="forgot-password/:active_code" element={<ForgotPasswordAdmin />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
