/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ID, KTSVG } from "helpers";
import { Product } from "../../../modules/products/products-list/core/_models";
import { getProductsBySearchKey } from "../../../modules/products/products-list/core/_requests";
import { TextCell } from "../table/columns/TextCell";
import  VariantImageCell  from "../table/columns/VariantImageCell";
import { ProductImageCell } from "../table/columns/ProductImageCell";

interface PropsInterfaces {
  updateState: any;
  placeholder?: string;
  state: any;
  attributes?: any;
  form?: any;
}

const ListSearchProduct = ({
  updateState,
  placeholder = "search...",
  state,
  form,
  attributes
}: PropsInterfaces) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [productList, setProductList] = useState<Product[]>();

  const searchProduct = async ()=>{
    const [isError, data] = await getProductsBySearchKey(`asc_sort=false&search_key=${searchTerm}`);
    if (!isError && data) {
      setProductList(data.content);
    }
  }

  const handleSubmitProduct = (id: ID, name: string) => {
    setSearchTerm(name);
    form.setFieldValue("product_id", id);
  };

  useEffect( () => {
    if (searchTerm === "") {
      setProductList([]);
    } else {
      searchProduct()
    }
  }, [searchTerm]);

  return (
    <div className="card-title">
      <div className="d-flex align-items-center position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6" />
        <input
          type="search"
          className="form-control form-control-solid w-300px ps-14"
          placeholder={placeholder}
          value={searchTerm !== "" ? searchTerm : ""}
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        {productList && productList?.map((product: Product) => {
          return (
            <>
                <div className="d-flex align-items-center" key={product?.id}>
                  <div className="min-w-125px">
                    {
                      <TextCell text={product.product_name} />
                    }
                  </div>
                  <div className="min-w-125px">
                    {
                      <ProductImageCell
                        id={product.id}
                        isDetail={true}
                        product={product.thumbnail} />
                    }
                  </div>

                  <button className="btn btn-primary"
                          type="button"
                          onClick={() => handleSubmitProduct(product?.id, product?.product_name)}>
                    Choose
                  </button>
                </div>
            </>
          );
        })}
      </div>

    </div>
  );
};
export { ListSearchProduct };
