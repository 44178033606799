import { CustomHeader } from "app/layout/components/table/columns/CustomHeader";
import { Column } from "react-table";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import ProductStatusCell from "../../../../../layout/components/table/columns/ProductStatusCell";
import { ActionsCell } from "app/layout/components/table/columns/ActionsCell";
import { TextCell } from "../../../../../layout/components/table/columns/TextCell";
import { NumberCell } from "../../../../../layout/components/table/columns/NumberCell";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="User email"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "UserEmail",
    Cell: ({ ...props }) =>
      <TextCell text={props.data[props.row.index].email} />
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Full Name"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "FullName",
    Cell: ({ ...props }) =>
      <TextCell text={`${props.data[props.row.index].first_name || " "} ${props.data[props.row.index].last_name || " "}`} />
  },
  {
    Header: (props: any) => (
      <CustomHeader
        tableProps={props}
        title="Role"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "Role",
    Cell: ({ ...props }) => (
      <TextCell text={props.data[props.row.index].user_role} />
    )
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Status"
        className={`min-w-125px`}
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "status",
    Cell: ({ ...props }) =>
      <ProductStatusCell status={props.data[props.row.index]?.is_receive_promotion ? "ACTIVE" : "INACTIVE"} />

  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ActionsCell
        id={props.data[props.row.index].id}
        useListView={useListView}
      />
    )
  }
];
export { usersColumns };
