import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { KTSVG } from "../../../helpers";


function ImageUpload(props: any) {
  const {
    attributes,
    setImageForm,
  } = props;
  const [image, setImage] = useState(attributes.value);
  const [isUploaded, setIsUploaded] = useState(false);
  const formData = new FormData();

  useEffect(() => {
    setImage(attributes.value);
  }, [attributes.value]);

  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setIsUploaded(false);
      setImage(URL.createObjectURL(e.target.files[0]));
      formData.append("file", e.target.files[0]);
      setImageForm(formData);
    }
  }

  const removeImage = () => {
    if (image?.startsWith("blob:")) {
      URL.revokeObjectURL(image);
    }
    setIsUploaded(false);
    setImage(null);
  };

  return (
    <div className={styles.container}>
      <label htmlFor="file-upload" className={styles.boxUpload}>
        {(!image && !isUploaded) ? (
          <>
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M6.15377 9.76902C6.15377 11.8927 7.87492 13.6152 9.99992
                          13.6152C12.1236 13.6152 13.8461 11.8927 13.8461 9.76902C13.8461
                          7.64466 12.1236 5.92286 9.99992 5.92286C7.87492 5.92286 6.15377
                            7.64466 6.15377 9.76902ZM5 9.76902C5 7.00777 7.23813 4.76902
                            10 4.76902C12.7613 4.76902 15 7.00777 15 9.76902C15 12.5296 12.7613
                              14.769 10 14.769C7.23813 14.769 5 12.5296 5 9.76902ZM1.15385
                              17.2607C0.489784 17.2607 0 16.725 0 16.0662V4.12224C0 3.4623 0.489784 2.84596
                                1.15385 2.84596H4.61538L5.21635 1.73273C5.21635 1.73273 5.75421 0.538269
                                6.41827 0.538269H13.5817C14.2452 0.538269 14.7837 1.73273 14.7837
                                  1.73273L15.3846 2.84596H18.8462C19.5096 2.84596 20 3.4623 20 4.12224V16.0662C20
                                  16.725 19.5096 17.2607 18.8462 17.2607H1.15385Z"
                    fill="black" fillOpacity="0.26"></path>
            </svg>
            <input
              id="file-upload"
              type="file"
              onChange={handleImageChange}
            />
          </>
        ) : (
          <>
            <div className={styles.closeIcon}
                 onClick={removeImage}>
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
            <img
              id="uploaded-image"
              src={image}
              draggable={false}
              alt="uploaded-img"
              style={{ width: "100%" }}
            />
          </>
        )}
      </label>
    </div>

  );
}

export default ImageUpload;