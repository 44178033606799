import { Dispatch, SetStateAction } from "react";
import { OrderStatus } from "../../app/modules/order-manager/order-list/core/_models";
import { ReviewApproved } from "../../app/modules/review/review-list/core/_models";
import { ContentType } from "../../app/modules/content/content-list/core/_models";

export type ID = undefined | null | number | string;

export type PaginationState = {
  sort?: boolean;
  sort_field?: string;
  search_key?: string;
  page_number?: number;
  page_size?: number;
  page: number;
  items_per_page: 10 | 30 | 50 | 100;
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>;
};

export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export type FilterState = {
  filter?: unknown;
};

export type SearchState = {
  search?: string;
};

export type Response<T> = {
  data?: T;
  payload?: {
    message?: string;
    errors?: {
      [key: string]: Array<string>;
    };
    pagination?: PaginationState;
  };
};

export type QueryState = {
  asc_sort: boolean;
  sort_field: string | undefined;
  search_key: string | undefined;
  page_number: number | undefined;
  page_size: number | undefined;
  total_pages: number;
  total_elements: number;
  order_status?: OrderStatus;
  is_approved?: ReviewApproved;
  content_type?: ContentType;
};

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState = {
  asc_sort: false,
  sort_field: "id",
  search_key: "",
  page_number: 1,
  page_size: 10,
  total_pages: 1,
  total_elements: 0
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {
  }
};

export type QueryResponseContextProps = {
  response?: any;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export const initialQueryResponse = {
  refetch: () => {
  },
  isLoading: false,
  query: ""
};

export type ListViewContextProps = {
  selected: Array<ID>;
  onSelect: (selectedId: ID) => void;
  onSelectAll: () => void;
  clearSelected: () => void;
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  isAllSelected: boolean;
  disabled: boolean;
};

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {
  },
  onSelectAll: () => {
  },
  clearSelected: () => {
  },
  setItemIdForUpdate: () => {
  },
  isAllSelected: false,
  disabled: false
};
