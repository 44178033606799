import { useMemo } from 'react';
import { useTable, ColumnInstance, Row } from 'react-table';

import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider';
import { KTCardBody } from 'helpers';
import { Pagination } from 'app/layout/components/pagnination/Pagination';
import { useQueryRequest } from '../core/QueryRequestProvider';
import { FetchListLoading } from 'app/layout/components/loading/FetchListLoading';
import { productsColumns } from './columns/_columns';
import { CustomHeaderColumn } from 'app/layout/components/table/columns/CustomHeaderColumn';
import { CustomRow } from 'app/layout/components/table/columns/CustomRow';

const ProductsTable = () => {
  const products = useQueryResponseData();
  const isLoading = useQueryResponseLoading();
  const pagination = useQueryResponsePagination();
  const { updateState } = useQueryRequest();
  const data = useMemo(() => products, [products]);

  const columns = useMemo(() => productsColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handlePages = (updatePage: number) => {
    updateState({ page_number: updatePage, page_size: pagination.items_per_page || 10 });
  };

  return (
    <KTCardBody className="py-4">
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        page={pagination?.page_number}
        totalPages={pagination?.total_pages}
        handlePagination={handlePages}
      />
      {isLoading && <FetchListLoading />}
    </KTCardBody>
  );
};

export { ProductsTable };
