import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import Req from "../../utils/request";
import { API_URI } from "../../constants/api";

interface TextEditorInterface {
  setValue: any;
  values: any;
}

const EditorC: any = Editor as any;

function TextEditor({
  setValue,
  values
}: TextEditorInterface) {
  const handleEditorChange = (value: any) => {
    setValue(value);
  };

  const example_image_upload_handler = async (data: any, success: any) => {
    const url = API_URI.BASE_URI + API_URI.PRODUCT + API_URI.IMAGE;
    const form: any = new FormData();
    form.append("file", data.blob());
    const response = await Req.POST(url, form);
    success(response.data);
  };

  return (
    <div className="App">
      <EditorC
        value={values}
        onEditorChange={handleEditorChange}
        init={{
          selector: "textarea#basic-example",
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          imagetools_cors_hosts: ["picsum.photos"],
          menubar: "file edit view insert format tools table help",
          table_border_styles: [
            {
              title: "Solid",
              value: "solid"
            },
            {
              title: "Dotted",
              value: "dotted"
            },
            {
              title: "Dashed",
              value: "dashed"
            }
          ],
          table_border_color_map: [
            {
              title: "Black",
              value: "000000"
            },
            {
              title: "Red",
              value: "FF0000"
            },
            {
              title: "White",
              value: "FFFFFF"
            },
            {
              title: "Yellow",
              value: "F1C40F"
            }
          ],
          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          content_css: [
            "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i",
            "//www.tiny.cloud/css/codepen.min.css"
          ],
          image_class_list: [
            {
              title: "None",
              value: ""
            },
            {
              title: "Some class",
              value: "class-name"
            }
          ],
          image_title: true,
          automatic_uploads: true,
          file_picker_types: "image",
          importcss_append: true,
          images_upload_handler: example_image_upload_handler,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                "<div class=\"mceTmpl\">" +
                "<table width=\"98%%\"  border=\"0\" cellspacing=\"0\" cellpadding=\"0\">" +
                "<tr><th scope=\"col\"> </th>" +
                "<th scope=\"col\"> </th></tr><tr><td> </td><td> </td></tr></table></div>"
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time..."
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                "<div class=\"mceTmpl\">" +
                "<span class=\"cdate\">cdate</span>" +
                "<br /><span class=\"mdate\">mdate</span>" +
                "<h2>My List</h2><ul><li></li><li></li></ul></div>"
            }
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_drawer: "sliding",
          spellchecker_dialog: true,
          spellchecker_whitelist: ["Ephox", "Moxiecode"],
          contextmenu: "link image imagetools table",
          height: 500,
          toolbar_sticky: false,
          menu: {
            tc: {
              title: 'Comments',
              items: 'addcomment showcomments deleteallconversations'
            }
          },
        }}
      />
    </div>
  );
}

export default TextEditor;