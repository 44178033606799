import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "helpers";
import { ReviewListHeader } from "./components/header/ReviewListHeader";
import { ReviewTable } from "./table/ReviewsTable";
import { EditReviewModal } from "./edit-modal/ReviewProductModal";

const ReviewList = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <KTCard>
        <ReviewListHeader />
        <ReviewTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditReviewModal />}
    </>
  );
};

const ReviewListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ReviewList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { ReviewListWrapper };
