import React, { FC, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { User } from "../core/_models";
import clsx from "clsx";
import { useListView } from "../core/ListViewProvider";
import { UsersListLoading } from "../components/loading/UsersListLoading";
import { updateInfoUser } from "../core/_requests";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { toastAlert } from "../../../../utils/request/toastify";
import { stringifyRequestQuery } from "../../../../../helpers";
import { useQueryRequest } from "../../../category/category-list/core/QueryRequestProvider";

type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object()
  .shape({
    first_name: Yup.string()
      .min(1, "First name can not be empty")
      .required("First name is required"),
    last_name: Yup.string()
      .min(1, "Last Name can not be empty")
      .required("Last Name is required")
  });

const UserEditModalForm: FC<Props> = ({
  user,
  isUserLoading
}) => {
  const { setItemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const formik = useFormik({
    initialValues: user,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (user.id) {
        const [error, data] = await updateInfoUser(values);
        if (!error && data) {
          toastAlert({
            type: "success",
            message: "Update User Success!"
          });
          setItemIdForUpdate(undefined);
          refetch();
        } else {
          toastAlert({
            type: "error",
            message: "Update User Failed!"
          });
        }
        setSubmitting(true);
        cancel(true);
      }
    }
  });

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  return (
    <>
      <form className="form" onSubmit={formik.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px">
          <div className="fv-row mb-7">
            <label htmlFor="first_name" className="required fw-bold fs-6 mb-2">First Name</label>
            <input
              id="first_name"
              placeholder="First Name"
              {...formik.getFieldProps("first_name")}
              type="text"
              name="first_name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.first_name && formik.errors.first_name },
                { "is-valid": formik.touched.first_name && !formik.errors.first_name }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.first_name && formik.errors.first_name && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.first_name}</span>
              </div>
            )}
            <label htmlFor="last_name" className="required fw-bold fs-6 mt-5">Last Name</label>
            <input
              id="last_name"
              placeholder="Last Name"
              {...formik.getFieldProps("last_name")}
              type="text"
              name="last_name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.last_name && formik.errors.last_name },
                { "is-valid": formik.touched.last_name && !formik.errors.last_name }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.last_name && formik.errors.last_name && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.last_name}</span>
              </div>
            )}
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Phone</label>
            <input
              placeholder="Phone"
              {...formik.getFieldProps("phone_number")}
              type="text"
              name="phone_number"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0 cursor-not-allowed opacity-50",
                { "is-invalid": formik.touched.phone_number && formik.errors.phone_number },
                { "is-valid": formik.touched.phone_number && !formik.errors.phone_number }
              )}
              disabled={true}
            />
          </div>
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Email</label>
            <input
              placeholder="Email"
              {...formik.getFieldProps("email")}
              type="text"
              name="email"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0 cursor-not-allowed opacity-50",
                { "is-invalid": formik.touched.email && formik.errors.email },
                { "is-valid": formik.touched.email && !formik.errors.email }
              )}
              disabled={true}
            />
          </div>
          {!(user.user_role === "GUEST") &&
            <div>
              <label className="fw-bold fs-6 mb-2">Status</label>
              <div className="col-lg-8 d-flex align-items-center">
                <div className="form-check form-check-solid form-switch fv-row">
                  <input
                    {...formik.getFieldProps("is_receive_promotion")}
                    className={clsx("form-check-input w-45px h-30px",
                      { "is-invalid": formik.touched.is_receive_promotion && formik.errors.is_receive_promotion },
                      { "is-valid": formik.touched.is_receive_promotion && !formik.errors.is_receive_promotion }
                    )}
                    type="checkbox"
                    defaultChecked={user.is_receive_promotion}
                    disabled={formik.isSubmitting || isUserLoading}
                  />
                  <label className="form-check-label"></label>
                </div>
              </div>
            </div>
          }


        </div>
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting || isUserLoading}>
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="editUser"
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid}>
            <span className="indicator-label">Save</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className="indicator-progress">
                  Please...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  );
};

export { UserEditModalForm };
