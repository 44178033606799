import clsx from "clsx";
import { FC, PropsWithChildren, useMemo } from "react";
import { HeaderProps } from "react-table";

type Props = {
  className?: string;
  title?: string;
  tableProps: PropsWithChildren<HeaderProps<any>>;
  state: any;
  updateState: any;
  isDetail?: boolean;
};
const CustomHeader: FC<Props> = ({
  className,
  title,
  tableProps,
  state,
  updateState
}) => {
  const id = tableProps.column.id;

  const isSelectedForSorting = useMemo(() => {
    return state?.sort_field === id;
  }, [state.sort_field, id]);
  const order: "asc" | "desc" | undefined = useMemo(
    () => (state?.asc_sort ? "asc" : "desc"),
    [state]
  );

  const sortColumn = () => {
    if (id === "actions" || id === "selection") {
      return;
    }
    updateState({
      ...state,
      asc_sort: !state?.asc_sort,
      sort_field: id
    });
    return;
  };

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
        isSelectedForSorting && order !== undefined && `table-sort-${order}`
      )}
      style={{ cursor: "pointer" }}
      onClick={sortColumn}>
      {title}
    </th>
  );
};

export { CustomHeader };

