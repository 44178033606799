export const menuItems = {
  DASHBOARD: {
    label: "DASHBOARD",
    route: "/dashboard",
    icon: "/media/icons/duotune/art/art011.svg",
    fontIcon: "bi-app-indicator"
  },
  PRODUCT: {
    label: "PRODUCT",
    route: "/products",
    icon: "/media/icons/duotune/general/gen061.svg",
    fontIcon: "bi-archive"
  },
  CATEGORY: {
    label: "CATEGORY",
    route: "/category",
    icon: "/media/icons/duotune/general/gen060.svg",
    fontIcon: "bi-layers"
  },
  ADMIN: {
    label: "ADMIN MANAGEMENT",
    route: "/admin",
    icon: "/media/icons/duotune/communication/com016.svg",
    fontIcon: "bi-person"
  },
  ORDER: {
    label: "ORDERS",
    route: "/orders",
    icon: "/media/icons/duotune/ecommerce/ecm001.svg",
    fontIcon: "bi-person"
  },
  REVIEW: {
    label: "REVIEW",
    route: "/review",
    icon: "/media/icons/duotune/general/gen003.svg",
    fontIcon: "bi-star"
  },
  USER: {
    label: "USER MANAGEMENT",
    route: "/user",
    icon: "/media/icons/duotune/technology/teh011.svg",
    fontIcon: "bi-layers"
  },
  SETTING: {
    label: "SETTING",
    route: "/setting",
    icon: "/media/icons/duotune/coding/cod001.svg",
    fontIcon: "bi-layers"
  },
  FORGOT_PASSWORD: {
    label: "FORGOT PASSWORD",
    route: "/forgot-password/:active_code",
    icon: "/media/icons/duotune/coding/cod001.svg",
    fontIcon: "bi-layers"
  },
  CONTENT: {
    label: "CONTENT",
    route: "/content",
    icon: "/media/icons/duotune/communication/com002.svg",
    fontIcon: "bi-star"
  },
  ACCOUNT: {
    label: "ACCOUNT",
    route: "/account",
    icon: "",
    fontIcon: "bi-app-indicator"
  },
};
