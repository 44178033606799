/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTSVG, toAbsoluteUrl } from "helpers";
import { FC } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "app/modules/auth";

const HeaderUserMenu: FC = () => {

  const {
    currentUser,
    logout
  } = useAuth();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img
              alt="Logo"
              src={currentUser?.avatar || toAbsoluteUrl("/media/avatars/blank.png")}
            />
          </div>
          <div className="d-flex flex-column" style={{
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            <div className="fw-bolder d-flex align-items-center fs-5">
              {currentUser?.first_name} {currentUser?.last_name}
              <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2" />
            </div>
            <a href="/account" className="fw-bold text-muted text-hover-primary fs-7" style={{
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="menu-item my-1">
        <Link to="/account" className="d-flex menu-link px-5">
          <KTSVG path="/media/icons/duotune/communication/com016.svg" className="svg-icon-2 d-block px-2" />
          Account Settings
        </Link>
      </div>
      <div className="menu-item my-1">
        <a onClick={logout} className="d-flex menu-link px-5">
          <KTSVG path="/media/icons/duotune/abstract/abs053.svg" className="svg-icon-2 d-block px-2" />
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
