import { Review } from "../core/_models";
import { useFormik } from "formik";
import { toastAlert } from "../../../../utils/request/toastify";
import React, { useEffect, useMemo, useState } from "react";
import { createReview, updateReview } from "../core/_request";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResponseProvider";
import clsx from "clsx";
import { KTSVG, stringifyRequestQuery } from "../../../../../helpers";
import { useQueryRequest } from "../core/QueryRequestProvider";
import Rating from "../../../../layout/components/table/columns/RatingCell";
import { DateCell } from "../../../../layout/components/table/columns/DateCell";
import { ListSearchProduct } from "../../../../layout/components/search/ListSearchProduct";

type Props = {
  review: Review
}

const StarRating = ({
  onChange,
  value
}: any) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              cursor: "pointer"
            }}
            key={index}
            onClick={() => onChange(index)}>
            <span className="star">
              <KTSVG
                path="/media/icons/duotune/general/gen003.svg"
                className={index <= (Number(value)) ? "text-danger" : "text-gray"} />
            </span>
          </button>
        );
      })}
    </div>
  );
};

const ReviewModalForm = ({ review }: Props) => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();
  const { refetch } = useQueryResponse();
  const [loading, setLoading] = useState(false);
  const {
    state,
    updateState
  } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const form = useFormik({
    initialValues: review,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      if (review.id) {
        const [error, data] = await updateReview(values);
        if (!error && data) {
          setLoading(false);
          toastAlert({
            type: "success",
            message: "Update Success!!!"
          });
          setItemIdForUpdate(undefined);
          refetch();
        } else {
          toastAlert({
            type: "error",
            message: "Update Error!!!"
          });
        }
      } else {

        const [error, data] = await createReview(values);
        if (!error && data) {
          setLoading(false);
          toastAlert({
            type: "success",
            message: "Add Success!!!"
          });
          setItemIdForUpdate(undefined);
          refetch();
        } else {
          toastAlert({
            type: "error",
            message: "Add Error!!!"
          });
        }
      }
      setSubmitting(true);
      cancel(true);
    }
  });

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);

  return (
    <form onSubmit={form.handleSubmit} noValidate className="form">
      <div className="card mb-5 mb-xl-10">
        <div className="d-flex flex-column scroll-y me-n7 pe-7">
          <div className="row mb-7">
            <label className="col-lg-4 fw-bold"><h3>Approved</h3></label>
            <div className="col-lg-8">
              <div className="form-check form-check-solid form-switch fv-row">
                <input
                  {...form.getFieldProps("is_approved")}
                  className={clsx("form-check-input w-45px h-30px")}
                  type="checkbox"
                  defaultChecked={review.is_approved} />
                <label className="form-check-label" />
              </div>
            </div>
          </div>
          <div className="row mb-7">
            <label className=" col-lg-4 fw-bold">
              {!itemIdForUpdate ? <h3 className="required">Rating</h3> : <h3>Rating</h3>}
            </label>
            <div className="col-lg-8"
                 {...form.getFieldProps("rating")}>
              {!itemIdForUpdate &&
                <StarRating
                  onChange={(value: string) => form.setFieldValue("rating", value)}
                  value={form.values?.rating || 0} />
              }
              {itemIdForUpdate &&
                <Rating rating={Number.parseInt(review.rating || "1", 10)}></Rating>
              }
            </div>
          </div>
          <div className="row mb-7">
            {itemIdForUpdate &&
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold"><h3>Full Name</h3></label>
                <div className="col-lg-8" {...form.getFieldProps("first_name")}>
                  {review.first_name + " " + review.last_name || ""}
                </div>
              </div>
            }
          </div>
          <div className="row mb-7">
            {itemIdForUpdate &&
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold"><h3>Created date</h3></label>
                <div className="col-lg-8">
                  <DateCell date={`${review?.created_date}`} />
                </div>
              </div>
            }
          </div>
          <div className="row mb-7">
            {!itemIdForUpdate &&
              <div className="row mb-7">
                <label htmlFor="review_title" className="col-lg-4 fw-bold"><h3>Title </h3></label>
                <input
                  id="review_title"
                  placeholder="Title"
                  {...form.getFieldProps("title")}
                  type="text"
                  name="title"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": !!form.errors.title },
                    { "is-valid": !!form.errors.title }
                  )}
                  autoComplete="off"
                />
                {form.errors.title && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{form.errors.title}</span>
                    </div>
                  </div>
                )}
              </div>
            }
            {itemIdForUpdate &&
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold"><h3>Title</h3></label>
                <div className="col-lg-8" {...form.getFieldProps("title")}>
                  {review.title || ""}
                </div>
              </div>
            }
          </div>
          <div className="row mb-7">
            {!itemIdForUpdate &&
              <div className="row mb-7">
                <div>
                  <label htmlFor="review_comment" className="col-lg-4 fw-bold">
                    <h3>Comment</h3>
                  </label>
                  <textarea id="review_comment"
                            className="form-control form-control-solid mb-3 mb-lg-0 "
                            style={{
                              height: 200
                            }}
                            {...form.getFieldProps("content")}
                            name="content" />
                </div>
                {form.errors.content && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{form.errors.content}</span>
                    </div>
                  </div>
                )}
              </div>}
            {itemIdForUpdate &&
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold"><h3>Comment</h3></label>
                <div className="col-lg-8" {...form.getFieldProps("content")}>
                  {review.content || ""}
                </div>
                <div className="col-lg-8" {...form.getFieldProps("image_url")}>
                  <img className="w-50 p-3" src={review.image_url || ""} alt={review.content || ""} />
                </div>
              </div>
            }
          </div>
          <div className="row mb-7">
            {!itemIdForUpdate &&
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold"><h3>Product</h3></label>
                <ListSearchProduct
                  placeholder="Search Product Name"
                  updateState={updateState} state={state}
                  {...form.getFieldProps("product_name")}
                  attributes={form.getFieldProps("product_id")}
                  form={form}
                />
              </div>
            }
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={form.isSubmitting || loading}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {!loading && "Save"}
            {loading && (
              <span className="indicator-progress d-block">
              Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export { ReviewModalForm };
