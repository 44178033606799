import { menuItems } from "app/constants/menu";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../layout/core";
import { SettingList } from "./SettingList";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Setting",
    path: menuItems.SETTING.route,
    isSeparator: false,
    isActive: false
  }
];

const SettingPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}></Route>
      <Route
        index
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Settings</PageTitle>
            <SettingList />
          </>
        }
      />
    </Routes>
  );
};

export default SettingPage;
