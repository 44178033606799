import { useQuery } from "react-query";

import { useState } from "react";
import { getSetting } from "../../core/_request";
import { PointModalForm } from "./PointModalForm";
import { Setting } from "../../core/_models";
import { useListView } from "../../core/ListViewProvider";

const SettingModalFormWrapper = () => {

  const {
    itemIdForUpdate
  } = useListView();

  const [detailReview, setDataSetting] = useState<Setting | null>();

  const {
    isLoading,
    error
  } = useQuery(
    `get-list-setting`,
    async () => {
      const [error, data] = await getSetting();
      if (!error && data) {
        if (data) {
          setDataSetting(data);
        } else {
          setDataSetting(null);
        }
        return data;
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  if (!itemIdForUpdate) {
    // @ts-ignore
    return <PointModalForm setting={{ id: detailReview?.id, facebook_url: detailReview?.facebook_url,tik_tok_url: detailReview?.tik_tok_url, rate: detailReview?.rate, youtube_url: detailReview?.youtube_url, youtube_video: detailReview?.youtube_video }} />;
  }
  if (!isLoading && !error && detailReview) {
    return <PointModalForm setting={detailReview} />;
  }
  return <span>Loading...</span>;
};

export { SettingModalFormWrapper };
