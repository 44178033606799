import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { handleUpload } from "../../../products/products-list/core/_requests";
import { KTSVG, stringifyRequestQuery } from "../../../../../helpers";
import { Partner } from "../core/_models";
import { createPartner, updatePartner } from "../core/_request";
import clsx from "clsx";
import ImageUpload from "../../../../utils/images/imageUpload";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { toastAlert } from "../../../../utils/request/toastify";
import { PartnerTable } from "../table/PartnerTable";

type Props = {
  partner: Partner
}

export function PartnerModalForm(Props: Props) {
  const { partner } = Props;
  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [imageForm, setImageForm] = useState<FormData>();
  const [isAddPartner, setIsAddPartner] = useState(itemIdForUpdate ? true : false);
  const [loading, setLoading] = useState(false);

  const handleUploadImage = async () => {
    if (imageForm) {
      const [error, data] = await handleUpload(imageForm);
      if (!error && data) {
        return data;
      } else {
        toastAlert({
          type: "error",
          message: "Upload Thumbnail Failed!"
        });
      }
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: partner,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const thumbnailURL = await handleUploadImage();
        if (!thumbnailURL && !partner.image) throw new Error();
        if (itemIdForUpdate) {
          const [error, data] = await updatePartner({
            ...values,
            image: thumbnailURL || partner.image
          });
          if (!error && data) {
            setLoading(false);
            toastAlert({
              type: "success",
              message: "Update Success!"
            });
            setItemIdForUpdate(undefined);
            refetch();
          } else {
            toastAlert({
              type: "error",
              message: "Update Failed!"
            });
          }
        } else {
          setLoading(true);
          const [error, data] = await createPartner({
            ...values,
            image: thumbnailURL || partner.image
          });
          if (!error && data) {
            setLoading(false);
            toastAlert({
              type: "success",
              message: "Create Success!"
            });
            setItemIdForUpdate(undefined);
            refetch();
          } else {
            toastAlert({
              type: "error",
              message: "Create Failed!"
            });
          }
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
        setLoading(false);
        form.resetForm();
        setIsAddPartner(false);
      }
    }
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    form.resetForm();
    setItemIdForUpdate(undefined);
  };

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  return (
    <div>
      <div className="fv-row mb-5 mt-5">
        {(isAddPartner || itemIdForUpdate) &&
          <form className="form" onSubmit={form.handleSubmit} noValidate>
            <div className="d-flex justify-content-between align-items-center mt-5">
              <div>
                <ImageUpload
                  form={form}
                  setImageForm={setImageForm}
                  attributes={form.getFieldProps("image")}
                  field={"image"}
                />
              </div>
              <input
                autoFocus={true}
                id="link_url"
                placeholder="Link"
                {...form.getFieldProps("link_url")}
                type="text"
                name="link_url"
                className={clsx(
                  "form-control form-control-solid w-650px"
                )}
                autoComplete="off"
                disabled={form.isSubmitting}
              />
              <button
                type="submit"
                className="btn btn-primary mx-5" disabled={loading}>
                {!loading && (
                  <span className="indicator-label">Save</span>)}
                {loading && (
                  <span className="indicator-progress d-block mx-5">
              Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                )}
              </button>
              {itemIdForUpdate &&
                <button
                  type="reset"
                  onClick={() => cancel(!isAddPartner)}
                  className="btn btn-light me-3">
                  Cancel
                </button>
              }
            </div>
          </form>
        }
        {!itemIdForUpdate &&
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setIsAddPartner(!isAddPartner)}>
            {!isAddPartner && <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />}
            {!isAddPartner ? "Add Partner"
              : "Cancel"}
          </button>
        }

      </div>
      <PartnerTable />
    </div>
  );
}