import { API_URI } from "app/constants/api";
import Req from "app/utils/request";
import { IAccount } from "./_model";

const API_URL = process.env.REACT_APP_API_URL;

export const updateAccount = async (account: IAccount, isPassword: boolean) => {
  try {
    const url = `${API_URL + API_URI.ADMIN}/${account.id}`;
    const bodyRequest = {
      email: account.email,
      first_name: account.first_name,
      last_name: account.last_name,
      avatar: account.avatar
    };
    const result = await Req.PUT(url, isPassword ? {
      ...bodyRequest,
      password_hash: account.password_hash,
      confirm_password: account.confirm_password
    } : bodyRequest);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export const updateAvatarAccount = async (avatar: any) => {
  try {
    const url = `${API_URL + API_URI.ADMIN}/${API_URI.UPLOAD_AVATAR}`;
    const form: any = new FormData();
    form.append("file", avatar);
    const result = await Req.POST(url, form);
    if (result.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, error];
  }
};
