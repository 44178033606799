
import { lazy, Suspense, ReactNode } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MasterLayout } from "../layout/MasterLayout";
import { getCSSVariableValue } from "assets/ts/_utils";
import { menuItems } from "app/constants/menu";
import ProductPage from "app/modules/products/ProductPage";
import AccountPage from "app/modules/admin/AccountPage";
import ReviewPage from "../modules/review/ReviewPage";
import UsersPage from "../modules/user-management/UsersPage";
import SettingPage from "../modules/setting/SettingPage";
import AccountSettingsPage from "../modules/account-setting/AccountSettingsPage";
import { ContentTable } from "../modules/content/content-list/table/ContentTable";
import ContentPage from "../modules/content/ContentPage";

interface PropsInterfaces {
  children: ReactNode;
}

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import("../pages/layout-builder/BuilderPageWrapper"));
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const OrderPage = lazy(() => import("../modules/order-manager/OrderPage"));
  const CategoryPage = lazy(() => import("../modules/category/CategoryPage"))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />

        <Route
          path={`${menuItems.ACCOUNT.route}/*`}
          element={
            <SuspensedView>
              <AccountSettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.ADMIN.route}/*`}
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.USER.route}/*`}
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.PRODUCT.route}/*`}
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.ORDER.route}/*`}
          element={
            <SuspensedView>
              <OrderPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.CATEGORY.route}/*`}
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.REVIEW.route}/*`}
          element={
            <SuspensedView>
              <ReviewPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.SETTING.route}/*`}
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />
        <Route
          path={`${menuItems.CONTENT.route}/*`}
          element={
            <SuspensedView>
              <ContentPage />
            </SuspensedView>
          }
        />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }: PropsInterfaces) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={'loading...'}>{children}</Suspense>;
};

export { PrivateRoutes };
