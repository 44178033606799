interface IPropsInterface {
  selected: any;
  onHandleDeleteSelectedItems?: any;
}

const GroupDeleteItems = ({ selected, onHandleDeleteSelectedItems }: IPropsInterface) => {

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div className="fw-bolder me-5">
        <span className="me-2">{selected.length}</span> Selected
      </div>
      <button
        type="button"
        className="btn btn-danger"
        onClick={async () => await onHandleDeleteSelectedItems.mutateAsync()}>
        Delete Selected
      </button>
    </div>
  );
};

export { GroupDeleteItems };
