import ReactDOM from "react-dom";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { Slide, ToastContainer } from "react-toastify";

import { AuthProvider, setupAxios } from "./app/modules/auth";
import { AppRoutes } from "./app/routing/AppRoutes";

import { getLayout } from "app/layout/core";
import React, { FC, useEffect, useMemo } from "react";
import "./assets/sass/style.react.scss";

const LightLayout = React.lazy(() => import("app/layout/LightLayout"));
const DarkLayout = React.lazy(() => import("app/layout/DarkLayout"));

setupAxios(axios);

Chart.register(...registerables);

const queryClient = new QueryClient();

const Layout: FC = () => {
  const layout = getLayout();

  const mode = useMemo(() => layout.themeMode, [layout.themeMode]);
  
  useEffect(() => {}, [mode]);

  switch (mode) {
    case "dark":
      return (
        <React.Suspense fallback={<></>}>
          <DarkLayout />
        </React.Suspense>
      );
    default:
      return (
        <React.Suspense fallback={<></>}>
          <LightLayout />
        </React.Suspense>
      );
  }
};

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Layout />
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
    <ToastContainer transition={Slide} />
  </QueryClientProvider>,
  document.getElementById("root"),
);
