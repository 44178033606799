/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect } from "react";
import { MenuComponent } from "../../../../../assets/ts/components";

type Props = {
  isDetail?: boolean,
  productVariant: string,
}

const VariantImageCell: FC<Props> = ({
  isDetail,
  productVariant,
}) => {

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <div className="d-flex align-items-center">
      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
          {productVariant? (
            <div className="symbol-label">
              <img src={productVariant} alt={productVariant} className="w-100 h-100" />
            </div>
          ) : (
            <div
              className={clsx(
                "symbol-label fs-3",
                `bg-light-${productVariant}`,
                `text-${productVariant}`
              )}>
              {productVariant}
            </div>
          )}
      </div>
    </div>
  );
};

export default VariantImageCell;
