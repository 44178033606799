/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { MenuComponent } from "assets/ts/components";
import { ID, KTSVG } from "helpers";

type Props = {
  id: ID;
  useListView: any;
};

const ActionsCell: FC<Props> = ({
  id,
  useListView,
}) => {
  const { setItemIdForUpdate } = useListView();


  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };

  return (
    <>
      <a
        href="#"
        className="btn btn-light btn-active-light-primary btn-sm"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end">
        View
        <KTSVG path="/media/icons/duotune/arrows/arr072.svg" className="svg-icon-5 m-0" />
      </a>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
        data-kt-menu="true">
        <div className="menu-item px-3">
          <a className="menu-link px-3" onClick={openEditModal}>
            Edit
          </a>
        </div>
      </div>
    </>
  );
};

export { ActionsCell };
