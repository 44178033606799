import { API_URI } from "app/constants/api";
import { ID } from "helpers";
import { Product } from "./_models";
import Req from "app/utils/request";
import axios from "axios";
import revalidateClientStaticPage from "../../../../utils/request/revalidateClientStaticPage";

const API_URL = process.env.REACT_APP_API_URL;

const getProductById = (id: ID) => {
  const url = `${API_URL + API_URI.PRODUCT}/${id}`;
  return Req.GET(url);
};

const getProducts = async (query?: string) => {
  try {
    const url = `${API_URL + API_URI.PRODUCT}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};
const getProductsBySearchKey = async (query?: string) => {
  try {
    const url = `${API_URL + API_URI.PRODUCT}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const addProduct = async (product: Product) => {
  try {
    const url = `${API_URL + API_URI.PRODUCT}`;
    const result = await Req.POST(url, product);
    if (result?.status === 200 && result?.data) {
    await  revalidateClientStaticPage('/');
    await  revalidateClientStaticPage(`/${result?.data?.slug}`);
      return [false, result.data];
    }
    return [true, result.data];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const handleUpload = async (imageFormData: FormData) => {
  try {
    const url = API_URI.BASE_URI + API_URI.PRODUCT + API_URI.IMAGE;
    const result = await Req.POST(url, imageFormData);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const updateProduct = async (productId: string | number, product: any) => {
  try {
    const url = `${API_URL + API_URI.PRODUCT}/${productId}`;
    const result = await Req.PUT(url, product);
    if (result?.status === 200 && result?.data) {
    await  revalidateClientStaticPage('/');
    await  revalidateClientStaticPage(`/${result?.data?.slug}`);
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const deleteSelectedProducts = (productIds: Array<ID>): Promise<void> => {
  const requests = productIds.map((id) => axios.delete(`}/${id}`));
  return axios.all(requests)
    .then(() => {
    });
};

const deleteProduct = async (productId: ID) => {
  try {
    const url = `${API_URL + API_URI.PRODUCT}/${productId}`;
    const result = await Req.DELETE(url);
    if (result?.status === 200 && result?.data) {
    await  revalidateClientStaticPage('/');
    await  revalidateClientStaticPage(`/${result?.data?.slug}`);
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getAllCategories = async (query?: string) => {
  try {
    const url = `${API_URI.CATEGORY}/all?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export {
  getProducts,
  getProductsBySearchKey,
  deleteSelectedProducts,
  deleteProduct,
  getProductById,
  addProduct,
  updateProduct,
  getAllCategories,
  handleUpload
};
