import { menuItems } from "app/constants/menu";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../layout/core";
import { ProductsListWrapper } from "./products-list/ProductsList";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Product",
    path: menuItems.PRODUCT.route,
    isSeparator: false,
    isActive: false,
  }
];

const ProductPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}></Route>
      <Route
        index
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Product Management</PageTitle>
            <ProductsListWrapper />
          </>
        }
      />
    </Routes>
  );
};

export default ProductPage;
