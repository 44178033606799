import React from "react";
import { toAbsoluteUrl } from "../AssetHelpers";
import SVG from "react-inlinesvg";

const SVGConvert: any = SVG as any;

type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
};

const KTSVG = ({ className = "", path, svgClassName = "mh-50px" }: Props) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVGConvert src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  );
};

export { KTSVG };
