import { menuItems } from "app/constants/menu";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageLink, PageTitle } from "../../layout/core";
import { ReviewListWrapper } from "./review-list/ReviewList";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Review",
    path: menuItems.REVIEW.route,
    isSeparator: false,
    isActive: false
  }
];

const ReviewPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}></Route>
      <Route
        index
        element={
          <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Review Management</PageTitle>
            <ReviewListWrapper />
          </>
        }
      />
    </Routes>
  );
};

export default ReviewPage;
