import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../core/_requests";
import { toastAlert } from "../../../utils/request/toastify";
import { KTSVG } from "../../../../helpers";

const initialValues = {
  email: ""
};

const forgotPasswordSchema = Yup.object()
  .shape({
    email: Yup.string()
      .email("Wrong email format")
      .max(50, "Maximum 50 symbols")
      .required("Email is required")
  });

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {
      setStatus,
      setSubmitting
    }) => {
      setSubmitting(true);
      setLoading(true);
      setHasErrors(undefined);
      const loginBody = {
        email: values.email,
        user_role: "SYSTEM_ADMIN"
      };
      const [error, data] = await requestPassword(loginBody);
      if (!error && data) {
        toastAlert({
          type: "success",
          message: "Update Success!"
        });
        setHasErrors(false);
        setLoading(false);
      } else {
        toastAlert({
          type: "error",
          message: "Update Failed!"
        });
        setHasErrors(true);
        setLoading(false);
        setSubmitting(false);
        setStatus("The login detail is incorrect");
      }
    }
  });

  return (
    <>
      <button
        type="button"
        className="btn btn-lg "
        disabled={formik.isSubmitting || !formik.isValid}>
        <Link to="/auth/login">
          <KTSVG path="/media/icons/duotune/art/art018.svg" className="svg-icon-1" />
        </Link>{" "}
      </button>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        onSubmit={formik.handleSubmit}>
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Forgot Password?</h1>
          <div className="text-gray-400 fw-bold fs-4">Enter your email to reset your password.</div>
        </div>
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}
        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">Sent password reset. Please check your email</div>
          </div>
        )}
        <div className="fv-row mb-10">
          <label htmlFor="email" className="form-label fw-bolder text-gray-900 fs-6">Email</label>
          <input
            id="email"
            type="email"
            placeholder="Email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control form-control-lg form-control-solid",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            className="btn btn-lg btn-primary fw-bolder me-4">
            <span className="indicator-label">Submit</span>
            {loading && (
              <span className="indicator-progress">
                Please wait...{""}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={formik.isSubmitting || !formik.isValid}>
              Cancel
            </button>
          </Link>{" "}
        </div>
      </form>
    </>
  );
}
