import { API_URI } from "app/constants/api";
import Req from "app/utils/request";
import axios from "axios";
import { AdminModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/verify_token`;
export const LOGIN_URL = `${API_URL}/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`;
interface LoginModel {
  login_id: string | undefined;
  password_hash: string;
  user_role: string;
  keep_login: boolean;
}

export async function login(data: LoginModel) {
  try {
    const rs = await Req.POST(API_URI.LOGIN, data);
    if (rs?.status === 200 && rs?.data) {
      return [false, rs.data];
    }
    return [true, rs.data];
  } catch (error) {
    console.log("getUserDetail error", error);
    return [true, `Server error, plz try again later!`];
  }
}

export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string,
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,

  });
}

interface ForgotPasswordModel {
  email: string | undefined;
  user_role: string;
}

export const requestPassword  = async (data: ForgotPasswordModel) =>{
  try {
    const result = await Req.POST(`${API_URL}/auth` + API_URI.FORGOT_PASSWORD, data)
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    console.log("getUserDetail error", error);
    return [true, `Server error, plz try again later!`];
  }
}


export const requestForgotPassword  = async (data: AdminModel, active_code:string) =>{
  try {
    const result = await Req.PUT(`${API_URL}/auth` + API_URI.CHANGE + "/" + active_code, data)
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    console.log("getUserDetail error", error);
    return [true, `Server error, plz try again later!`];
  }
}

export const getUserDetail = async (authToken: string) => {
  try {
    const result = await Req.GET("/auth/info", {
      'Auth-Token': authToken,
    });
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, result.data];
  } catch (error) {
    console.log("getUserDetail error", error);
    return [true, `Server error, plz try again later!`];
  }
};
