import { ID } from "../../../../../helpers";
import { API_URI } from "../../../../constants/api";
import Req from "../../../../utils/request";
import axios from "axios";
import { Review } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const getReviewById = (id: ID) => {
  const url = `${API_URL + API_URI.REVIEW}/public/${id}`;
  return Req.GET(url);
};

const getReviews = async (query?: string) => {
  try {
    const url = `${API_URL + API_URI.REVIEW}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const updateReview = async (review: Review) => {
  try {
    const url = `${API_URL+API_URI.REVIEW}/${review.id}`;
    const result = await Req.PUT(url, review);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const createReview = async (review: Review) => {
  try {
    const url = `${API_URL + API_URI.REVIEW}`;
    const result = await Req.POST(url, review);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const deleteSelectedReviews = (reviewIds: Array<ID>): Promise<void> => {
  const requests = reviewIds.map((id) => axios.delete(`}/${id}`));
  return axios.all(requests).then(() => {});
};

const deleteReview = async (reviewId: ID) => {
  try {
    const url = `${API_URL+API_URI.REVIEW}/${reviewId}`;
    const result = await Req.DELETE(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export {getReviewById, getReviews, deleteSelectedReviews,updateReview,deleteReview,createReview}