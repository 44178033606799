/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useFormik } from "formik";
import md5 from "md5";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuth } from "../core/Auth";
import { getUserDetail, login } from "../core/_requests";
import { toastAlert } from "../../../utils/request/toastify";
import { useQueryResponse } from "../../admin/admins-list/core/QueryResopnseProvider";

const loginSchema = Yup.object()
  .shape({
    email: Yup.string()
      .trim()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    password: Yup.string()
      .trim()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required")
  });

const initialValues = {
  email: "",
  password: ""
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const {
    saveAuth,
    setCurrentUser
  } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {
      setStatus,
      setSubmitting
    }) => {
      setLoading(true);
      try {
        const loginBody = {
          login_id: values.email,
          password_hash: md5(values.password),
          user_role: "SYSTEM_ADMIN",
          keep_login: true
        };
        const [loginError, auth] = await login(loginBody);

        if (loginError) throw Error(auth);
        if (!loginError && auth) {
          const authData = {
            api_token: auth?.id,
            refreshToken: auth?.expiry_date
          };
          saveAuth(authData);
          const [error, data] = await getUserDetail(auth.id);
          if (!error && data) {
            setCurrentUser(data);
          }
          setLoading(false);
          navigate(0);
          toastAlert({
            type: "success",
            message: "Login Success!"
          });
        }
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
        if (error instanceof Error) {
          toastAlert({
            type: "error",
            message: error.message
          });
          formik.resetForm();
        }
      }
    }
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form">
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign In to Calobye Admin</h1>
      </div>
      <div className="fv-row mb-10">
        <label htmlFor="email" className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          id="email"
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            { "is-valid": formik.touched.email && !formik.errors.email }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label htmlFor="password" className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
          </div>
        </div>
        <input
          id="password"
          placeholder="Password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between mt-n5 mb-3">
        <Link
          to="/auth/forgot-password"
          className="link-primary fs-6 fw-bolder"
          style={{ marginLeft: "5px" }}>
          Forgot Password ?
        </Link>{""}
      </div>
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}>
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
