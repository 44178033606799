import React, { FC, useEffect, useMemo, useState } from "react";
import { Product, ProductVariant } from "../core/_models";
import { useFormik } from "formik";
import clsx from "clsx";

import {
  addProduct,
  getAllCategories, handleUpload
} from "../core/_requests";
import * as Yup from "yup";
import TextEditor from "../../../../utils/texteditor";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResponseProvider";
import { Category } from "../../../category/category-list/core/_models";
import { useQuery } from "react-query";
import { KTSVG, stringifyRequestQuery } from "../../../../../helpers";
import { useQueryRequest } from "app/modules/category/category-list/core/QueryRequestProvider";
import SelectBox from "../../../../layout/components/selectbox/SelectBox";
import { toastAlert } from "../../../../utils/request/toastify";
import VariantImageCell from "../../../../layout/components/table/columns/VariantImageCell";
import AddVariantForm from "../../product-variant-edit-modal/AddVariantForm";
import { NumberCell } from "../../../../layout/components/table/columns/NumberCell";
import { TextCell } from "../../../../layout/components/table/columns/TextCell";
import { UsersListLoading } from "../../../user-management/users-list/components/loading/UsersListLoading";
import ImageUpload from "../../../../utils/images/imageUpload";

type Props = {
  isLoading: boolean;
  product: Product;
};

const editProductSchema = Yup.object()
  .shape({
    product_name: Yup.string()
      .trim()
      .required("Product name Required"),
    description: Yup.string()
      .trim()
      .required("Description Required"),
    category_id: Yup.string()
      .trim()
      .required("Category Required")
  });

const AddProductForm: FC<Props> = ({
  product,
  isLoading
}) => {
  const {
    setItemIdForUpdate
  } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [isAddVariant, setIsAddVariant] = useState(false);
  const [currentVariant, setCurrentVariant] = useState() as any;
  const [productVariants, setProductVariants] = useState<Array<ProductVariant> | []>([]);
  const [imageForm, setImageForm] = useState<FormData>();

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const handleUploadImage = async () => {
    if (imageForm) {
      const [error, data] = await handleUpload(imageForm);
      if (!error && data) {
        return data;
      } else {
        toastAlert({
          type: "error",
          message: "Upload Thumbnail Failed!"
        });
      }
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: product,
    validationSchema: editProductSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const thumbnailURL = await handleUploadImage();
        if (!thumbnailURL && !product.thumbnail) throw Error();
        const [error, data] = await addProduct({
          ...values,
          thumbnail: thumbnailURL || product.thumbnail,
          product_variants: productVariants
        });
        if (!error && data) {
          toastAlert({
            type: "success",
            message: "Create Product Success!"
          });
          setItemIdForUpdate(undefined);
          refetch();
        } else {
          toastAlert({
            type: "error",
            message: "Create Product Failed!"
          });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    }
  });

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const onHandleEditVariant = (variant: ProductVariant) => {
    setIsAddVariant(true);
    setCurrentVariant(variant);
  };

  const onHandleVariant = (variant: ProductVariant) => {
    setProductVariants([...productVariants, variant]);
    setIsAddVariant(false)
    setCurrentVariant({})
  };

  const onSoftDelete = (variant: ProductVariant) => {
    const newListVariant = productVariants.filter((item) => item.id !== variant.id);
    setProductVariants([...newListVariant]);
  };

  const {
    data: listCategories,
    isLoading: onGetCategoriesLoading
  } = useQuery(
    `get-list-categories`,
    async () => {
      const [error, categories] = await getAllCategories();
      if (!error && categories) {
        return categories;
      }
      return [];
    }
  );
  return (
    <>
      <form id="kt_modal_add_product_form" className="form" onSubmit={form.handleSubmit} noValidate>
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_product_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_product_header"
          data-kt-scroll-wrappers="#kt_modal_add_product_scroll"
          data-kt-scroll-offset="300px">
          <div className="fv-row mb-7">
            <label htmlFor="product_name" className="required fw-bold fs-6 mb-2 cursor-pointer">Product Name</label>
            <input
              id="product_name"
              placeholder="Product Name"
              {...form.getFieldProps("product_name")}
              type="text"
              name="product_name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": form.touched.product_name && form.errors.product_name },
                { "is-valid": form.touched.product_name && !form.errors.product_name }
              )}
              autoComplete="off"
              disabled={form.isSubmitting || isLoading}
            />
            {form.touched.product_name && form.errors.product_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{form.errors.product_name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="fv-row mb-7">
            <label htmlFor="description" className="required fw-bold fs-6 mb-2 cursor-pointer">Subtitle</label>
            <input
              id="description"
              placeholder="Subtitle"
              {...form.getFieldProps("description")}
              type="text"
              name="description"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": form.touched.description && form.errors.description },
                {
                  "is-valid": form.touched.description && !form.errors.description
                }
              )}
              autoComplete="off"
              disabled={form.isSubmitting || isLoading}
            />
            {form.touched.description && form.errors.description && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{form.errors.description}</span>
                </div>
              </div>
            )}
          </div>

          <div className="d-flex mb-5 justify-content-between">
            <div>
              <div className="d-flex">
                <div className="fv-row">
                  <label htmlFor="price" className="fw-bold fs-6 mb-2 cursor-pointer">Original Price</label>
                  <input
                    id="price"
                    placeholder="Price"
                    {...form.getFieldProps("price")}
                    type="number"
                    name="price"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0",
                      { "is-invalid": form.touched.price && form.errors.price },
                      {
                        "is-valid": form.touched.price && !form.errors.price
                      }
                    )}
                    autoComplete="off"
                    disabled={form.isSubmitting || isLoading}
                  />
                  {form.touched.price && form.errors.price && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{form.errors.price}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="fv-row mx-5">
                  <label htmlFor="renewable_price" className="fw-bold fs-6 mb-2 cursor-pointer">Sale Price</label>
                  <input
                    id="renewable_price"
                    placeholder="Renewable Price"
                    {...form.getFieldProps("renewable_price")}
                    type="text"
                    name="renewable_price"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0",
                      { "is-invalid": form.touched.renewable_price && form.errors.renewable_price },
                      { "is-valid": form.touched.renewable_price && !form.errors.renewable_price }
                    )}
                    autoComplete="off"
                    disabled={form.isSubmitting || isLoading}
                  />
                  {form.touched.renewable_price && form.errors.renewable_price && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{form.errors.renewable_price}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="fv-row mb-5">
                {!onGetCategoriesLoading && (
                  <SelectBox
                    values={listCategories.map((category: Category) => {
                      return {
                        value: category.id,
                        label: category.name
                      };
                    })}
                    label="Category"
                    required
                    attributes={form.getFieldProps("category_id")}></SelectBox>
                )}
                {form.touched.category_id && form.errors.category_id && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{form.errors.category_id}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="fv-row mx-5">
              <label className="required fw-bold fs-6">Thumbnail</label>
              <ImageUpload
                setImageForm={setImageForm}
                attributes={form.getFieldProps("thumbnail") || product.thumbnail}
                field={"thumbnail"}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="fv-row mb-7">
                <label className="fw-bold fs-6 mb-2">Is New</label>
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="form-check form-check-solid form-switch fv-row">
                    <input
                      {...form.getFieldProps("is_new")}
                      className={clsx("form-check-input w-45px h-30px")}
                      type="checkbox"
                      defaultChecked={product.is_new}
                    />
                    <label className="form-check-label" />
                  </div>
                </div>
              </div>
              <div className="fv-row mb-7 mx-5">
                <label className="fw-bold fs-6 mb-2">Is Best</label>
                <div className="col-lg-8 d-flex align-items-center">
                  <div className="form-check form-check-solid form-switch fv-row">
                    <input
                      {...form.getFieldProps("is_best")}
                      className={clsx("form-check-input w-45px h-30px")}
                      type="checkbox"
                      defaultChecked={product.is_best}
                    />
                    <label className="form-check-label" />
                  </div>
                </div>
              </div>
            </div>
            <div className="fv-row mb-7 ">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setIsAddVariant(!isAddVariant)}>
                <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
                Add Product Variant
              </button>
            </div>
          </div>

          {isAddVariant && (
            <AddVariantForm onHandleSubmit={onHandleVariant} variant={currentVariant} />
          )}
          <div>
            {productVariants &&
              productVariants?.map((variant: ProductVariant) => (
                <div className="d-flex align-items-center">
                  <div className="min-w-125px">{<TextCell text={variant.title} />}</div>
                  <div className="min-w-125px">
                    {<NumberCell className={variant.renewable_price ? "text-decoration-line-through" : ""} number={variant.price} />}
                    {variant?.renewable_price > 0 &&
                      <NumberCell number={variant.renewable_price} />}
                  </div>
                  <div className="min-w-125px">
                    {<VariantImageCell isDetail={true} productVariant={variant.image_url} />}
                  </div>
                  <div className="min-w-125px">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => onSoftDelete(variant)}>
                      <span className="indicator-label">Delete</span>
                    </button>
                  </div>
                  {variant?.status ? (
                    <div className="min-w-125px">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onHandleEditVariant(variant)}>
                        <span className="indicator-label">Edit</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </div>
          <div className="fv-row mb-2 pt-2">
            <label className="fw-bold fs-6 mb-2">Description</label>
            <TextEditor
              {...form.getFieldProps("product_detail_info")}
              setValue={(text: string) => form.setFieldValue("product_detail_info", text)}
              values={form.values.product_detail_info}
            />
          </div>
          <div className="fv-row mb-2 pt-2">
            <label className="fw-bold fs-6 mb-2">Nutritional Ingredients</label>
            <TextEditor
              {...form.getFieldProps("raw_material_and_content")}
              setValue={(text: string) => form.setFieldValue("raw_material_and_content", text)}
              values={form.values.raw_material_and_content}
            />
          </div>
          <div className="fv-row mb-2 pt-2">
            <label className="fw-bold fs-6 mb-2">How To Use</label>
            <TextEditor
              {...form.getFieldProps("user_manual")}
              setValue={(text: string) => form.setFieldValue("user_manual", text)}
              values={form.values.user_manual}
            />
          </div>
          <div className="row mb-7">
            <label className="fw-bold fs-6 mb-2">Active/InActive</label>
            <div className="col-lg-8 d-flex align-items-center">
              <div className="form-check form-check-solid form-switch fv-row">
                <input
                  {...form.getFieldProps("is_public")}
                  className={clsx("form-check-input w-45px h-30px")}
                  type="checkbox"
                  defaultChecked={product?.is_public}
                  disabled={form.isSubmitting || isLoading}
                />
                <label className="form-check-label"></label>
              </div>
            </div>
          </div>
          <div className="btn-group pt-2" role="group" aria-label="Basic example">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              disabled={form.isSubmitting || isLoading}>
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="addProduct"
              disabled={isLoading || form.isSubmitting || !form.isValid || !form.touched}>
              <span className="indicator-label">Save All</span>
              {(form.isSubmitting || isLoading) && (
                <span className="indicator-progress">
                  Please...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(form.isSubmitting || isLoading) && <UsersListLoading />}
    </>
  );
};
export { AddProductForm };