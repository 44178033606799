import { CustomHeader } from "app/layout/components/table/columns/CustomHeader";
import { DateCell } from "app/layout/components/table/columns/DateCell";
import { Column } from "react-table";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { ReviewActionsCell } from "../../../../../layout/components/table/columns/ReviewActionsCell";
import ReviewStatusCell from "../../../../../layout/components/table/columns/ReviewStatusCell";
import Rating from "../../../../../layout/components/table/columns/RatingCell";
import { toastAlert } from "../../../../../utils/request/toastify";
import { ID } from "../../../../../../helpers";
import { deleteReview } from "../../core/_request";
import { TextCell } from "../../../../../layout/components/table/columns/TextCell";
import React from "react";

const onHandleDelete = async (reviewId: ID) => {
  const [error, data] = await deleteReview(reviewId);
  if (!error && data) {
    toastAlert({
      type: "success",
      message: "Delete Success!"
    });
  } else {
    toastAlert({
      type: "error",
      message: "Delete Failed!"
    });
  }
};

const reviewColumns: ReadonlyArray<Column<any>> = [
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Rating"
        className="min-w-150px mr-2"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "rating",
    Cell: ({ ...props }) => (
      <Rating rating={Number.parseInt(`${props.data[props.row.index].rating || "1"}`, 10)} />
    )
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Title"
        className="w-150px h-50px over-flow-hidden truncate"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "content",
    Cell: ({ ...props }) =>
      <TextCell text={`${props.data[props.row.index].title || ""}`} />
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Product Name"
        className="min-w-150px mr-2"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "product_name",
    Cell: ({ ...props }) =>
      <TextCell text={`${props.data[props.row.index].product_name || ""}`} />
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Full Name"
        className="min-w-150px mr-2"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "FullName",
    Cell: ({ ...props }) =>
      <TextCell text={`${props.data[props.row.index].first_name || " "} 
       ${props.data[props.row.index].last_name || " "}`} />
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Create Date"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "created_date",
    Cell: ({ ...props }) => (
      <DateCell date={`${props.data[props.row.index]?.created_date}`} />
    )
  },

  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Status"
        className={`min-w-100px`}
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "status",
    Cell: ({ ...props }) =>
      <ReviewStatusCell approved={props.data[props.row.index]?.is_approved ? "APPROVED" : "NOT APPROVED"} />
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ReviewActionsCell
        id={props.data[props.row.index].id}
        useListView={useListView}
        useQueryResponse={useQueryResponse}
        onHandleDelete={onHandleDelete}
      />
    )
  }
];

export { reviewColumns };
