import { CustomHeader } from "app/layout/components/table/columns/CustomHeader";
import { ProductActionsCell } from "app/layout/components/table/columns/ProductActionsCell";
import { Column } from "react-table";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResopnseProvider";
import { ID } from "../../../../../../helpers";
import { toastAlert } from "../../../../../utils/request/toastify";
import { deleteAdmin } from "../../core/_requests";
import  ProductStatusCell  from "app/layout/components/table/columns/ProductStatusCell";
import { TextCell } from "../../../../../layout/components/table/columns/TextCell";

const onHandleDelete = async (adminId: ID) => {
  const [error, data] = await deleteAdmin(adminId);
  if (!error && data) {
    toastAlert({
      type: "success",
      message: "Delete Success!"
    });
  } else {
    toastAlert({
      type: "error",
      message: "Delete Failed!"
    });
  }
};

const adminsColumns: ReadonlyArray<Column<any>> = [
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Email"
        className="min-w-150px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "Username",
    Cell: ({ ...props }) =>  <TextCell text={props.data[props.row.index].email} />,
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Full Name"
        className="min-w-150px mr-2"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "FullName",
    Cell: ({ ...props }) =>  <TextCell text={`${props.data[props.row.index].first_name} ${props.data[props.row.index].last_name}` } />,
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Status"
        className=" min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "status",
    Cell: ({ ...props }) =>
      (
      <ProductStatusCell status={props.data[props.row.index]?.is_public? "ACTIVE" : "INACTIVE"} />
      )

  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ProductActionsCell
        id={props.data[props.row.index].id}
        useListView={useListView}
        useQueryResponse={useQueryResponse}
        onHandleDelete={onHandleDelete}
      />
    ),
  },
];

export { adminsColumns };
