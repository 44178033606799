import { Column } from "react-table";

import { CustomHeader } from "app/layout/components/table/columns/CustomHeader";
import { ProductActionsCell } from "app/layout/components/table/columns/ProductActionsCell";

import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { toastAlert } from "../../../../../utils/request/toastify";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { ID } from "../../../../../../helpers";
import React from "react";
import { BannerImageCell } from "../../../../../layout/components/table/columns/BannerImageCell";
import { TextUrlCell } from "../../../../../layout/components/table/columns/TextUrlCell";
import ProductStatusCell from "../../../../../layout/components/table/columns/ProductStatusCell";
import { deletePartner } from "../../core/_request";
import { PartnerImageCell } from "../../../../../layout/components/table/columns/PartnerImageCell";

const onHandleDelete = async (partnerId: ID) => {
  const [error, data] = await deletePartner(partnerId);
  if (!error && data) {
    toastAlert({
      type: "success",
      message: "Delete Success!"
    });
  } else {
    toastAlert({
      type: "error",
      message: "Delete Failed!"
    });
  }
};

const partnerColumns: ReadonlyArray<Column<any>> = [
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Image"
        className="text-start min-w-50"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "image",
    Cell: ({ ...props }) =>
      <PartnerImageCell
        id={props.data[props.row.index].id}
        isDetail={true}
        partner={props.data[props.row.index]?.image} />
  },
  {
    Header: (props: any) => (
      <CustomHeader
        tableProps={props}
        title="Link"
        className="text-start"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "link",
    Cell: ({ ...props }) => (
      <TextUrlCell text={props.data[props.row.index]?.link_url || ""} />
    )
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ProductActionsCell
        id={props.data[props.row.index].id}
        useListView={useListView}
        useQueryResponse={useQueryResponse}
        onHandleDelete={onHandleDelete}
      />
    )
  }
];

export { partnerColumns };
