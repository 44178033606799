
import React from "react";
import { KTCard } from "helpers";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ListViewProvider } from "./core/ListViewProvider";
import { PartnerModalFormWrapper } from "./partner-mode/PartnerModalFormWrapper";

const PartnerList = () => {
  return (
    <>
      <KTCard>
        <PartnerModalFormWrapper />
      </KTCard>
    </>
  );
};

const PartnerListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <PartnerList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { PartnerListWrapper };