import Req from "app/utils/request";
import { ID } from "helpers";
import { Admin } from "./_models";
import { API_URI } from "../../../../constants/api";

const API_URL = process.env.REACT_APP_API_URL;
const ADMIN_URL = `${API_URL}/admin`;

const getAdminById = (id: ID) => {
  const url = `${API_URL + API_URI.ADMIN}/${id}`;
  return Req.GET(url);
};

const getAdmin = async (query?: string) => {
  try {
    const url = `${ADMIN_URL}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

 const createAdmin = async (admin: Admin)=>{
   try {
     const url = `${API_URL + API_URI.ADMIN}`;
     const result = await Req.POST(url, admin);
     if (result?.status === 200 && result?.data) {
       return [false, result.data];
     }
     return [true, result.data];
   }catch (error) {
     return [true, `Server error, plz try again later!`];
   }
 }

const updateAdmin = async (admin: Admin)=>{
  try {
    const url = `${API_URL + API_URI.ADMIN}/${admin.id}`;
    const result = await Req.PUT(url, admin);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const deleteAdmin = async (adminId: ID) => {
  try {
    const url = `${API_URL + API_URI.ADMIN}/${adminId}`;
    const result = await Req.DELETE(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export { getAdmin, createAdmin, getAdminById, updateAdmin, deleteAdmin };
