/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery
} from "helpers";
import { useQueryRequest } from "./QueryRequestProvider";
import { getPageBanner } from "./_request";

const QueryResponseContext = createResponseContext(initialQueryResponse);

const QueryResponseProvider: FC = ({ children }) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response
  } = useQuery(
    `get-list-banner${query}`,
    async () => {
      const [error, data] = await getPageBanner(query);
      if (!error && data) {
        return data;
      }
    },
    {
      cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  );

  return (
    <QueryResponseContext.Provider value={{
      isLoading: isFetching,
      refetch,
      response,
      query
    }}>
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse: any = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.content || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: any = {
    ...initialQueryState
  };

  const { response } = useQueryResponse();
  if (!response || !response.page_number) {
    return defaultPaginationState;
  }
  const pagination = {
    page_number: response.page_number,
    page_size: response.page_size,
    total_pages: response.total_pages,
    total_elements: response.total_elements
  };
  return pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading
};

