export const API_URI = {
  PRODUCT: '/product',
  LOGIN: '/auth/login',
  BASE_URI: process.env.REACT_APP_API_URL || '',
  ORDER: '/orders',
  CUSTOMER: '/customer',
  CATEGORY: '/category',
  IMAGE: '/upload-image',
  ADMIN : '/admin',
  PRODUCT_VARIANT: '/product-variant',
  REVIEW: '/review',
  UPLOAD_AVATAR: '/upload-avatar',
  HASH_BOARD: '/dashboard',
  CHANGE: '/change-password',
  AUTH: '/auth',
  FORGOT_PASSWORD: '/forgot-password',
  SETTING: '/setting',
  BANNER: '/banner',
  PUBLIC: '/public',
  CONTENT: '/content',
  PARTNER: '/partner'
};
