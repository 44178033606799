import Req from "app/utils/request";
import axios  from "axios";
import { ID } from "helpers";
import { User } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/customer`;

const getUsers = async (query?: string, token?: string | undefined) => {
      try {
        const url = `${USER_URL}?${query}`;
        const result = await Req.GET(url);
        if (result?.status === 200 && result?.data) {
          return [false, result.data];
        }
        return [true, null];
      } catch (error) {
        return [true, `Server error, plz try again later!`];
      }
};

const updateInfoUser = async (user: User) => {
  try {
    const url = `${USER_URL}/${user.id}`;
    const result = await Req.PUT(url,user);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};
const getUserById = (id: ID) => {
  const url = `${USER_URL}/${id}`;
  return Req.GET(url);
};

const deleteUser = async (id: ID) => {
  return Req.DELETE(USER_URL, id);
};

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`));
  return axios.all(requests)
    .then(() => {
    });
};

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, updateInfoUser };
