import * as Yup from "yup";
import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";

import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResopnseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { Content, contentTypeOptions } from "../core/_models";

import { stringifyRequestQuery } from "../../../../../helpers";
import { toastAlert } from "../../../../utils/request/toastify";
import { createContent, updateContent } from "../core/_request";
import SelectBox from "../../../../layout/components/selectbox/SelectBox";
import TextEditor from "../../../../utils/texteditor";

type Props = {
  content: Content;
};

const contentSchema = Yup.object()
  .shape({
    title: Yup.string()
      .trim()
      .required("Title is required")
  });

export function ContentModalForm(Props: Props) {
  const { content } = Props;
  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [loading, setLoading] = useState(false);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: content,
    validationSchema: contentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (itemIdForUpdate) {
          const [error, data] = await updateContent(values);

          if (!error && data) {
            setLoading(false);
            toastAlert({
              type: "success",
              message: "Update Success!"
            });
            setItemIdForUpdate(undefined);
            refetch();
          } else {
            toastAlert({
              type: "error",
              message: "Update Failed!"
            });
          }
        } else {
          setLoading(true);
          const [error, data] = await createContent(values);
          if (!error && data) {
            setLoading(false);
            toastAlert({
              type: "success",
              message: "Create Success!"
            });
            setItemIdForUpdate(undefined);
            refetch();
          } else {
            toastAlert({
              type: "error",
              message: data
            });
          }
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        setLoading(false);
        cancel(true);
      }
    }
  });

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  return (
    <form onSubmit={form.handleSubmit} noValidate>
      <div className="mt-2">
        <label htmlFor="content_title" className="required fw-bold fs-6">Title</label>
        <input
          id="content_title"
          placeholder="Title"
          {...form.getFieldProps("title")}
          type="text"
          name="title"
          className={clsx(
            "form-control form-control-solid mb-3 mb-lg-0",
            {
              "is-invalid": form.touched.title && form.errors.title
            },
            {
              "is-valid": form.touched.title && !form.errors.title
            }
          )}
          autoComplete="off"
          disabled={form.isSubmitting}
        />
        {form.touched.title && form.errors.title && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{form.errors.title}</span>
            </div>
          </div>
        )}
      </div>
      <div className="mt-2">
        <label htmlFor="short_description" className="fw-bold fs-6 mb-2 mt-2">Short Description</label>
        <textarea id="short_description"
                  className="form-control mb-3 mb-lg-0 mb-5 fst-italic"
                  style={{
                    height: 150,
                    width: 400
                  }}
                  {...form.getFieldProps("description")}
                  name="description" />
      </div>
      <div className="mt-2">
        <label className="fw-bold fs-6 mb-2 mt-2">Content Type</label>
        {content.content_type === "NEWS"
          &&
          <input type="text" className="form-control form-control-solid fw-bold fs-6 mb-2 w-250px"
                 style={{ cursor: "not-allowed" }}
                 placeholder="NEWS" disabled={true} />
        }
        {content.content_type === "FAQ"
          &&
          <input type="text" className="form-control form-control-solid fw-bold fs-6 mb-2 w-250px"
                 style={{ cursor: "not-allowed" }}
                 placeholder="FAQ" disabled={true} />
        }
        {!(content.content_type === "NEWS") && !(content.content_type === "FAQ") &&
          <SelectBox
            className="w-250px"
            values={contentTypeOptions.map((option) => {
              return {
                value: option,
                label: option
              };
            })}
            attributes={form.getFieldProps("content_type")}
          />
        }
      </div>
      <div className="mt-2">
        <label htmlFor="content_title" className="fw-bold fs-6 mb-2 mt-2">Post Content</label>
        <TextEditor
          {...form.getFieldProps("post_content")}
          setValue={(text: string) => form.setFieldValue("post_content", text)}
          values={form.values.post_content}
        />
      </div>
      <div className="row mt-2">
        <label className="fw-bold fs-6 mb-2">Publish/UnPublish</label>
        <div className="col-lg-8 d-flex align-items-center">
          <div className="form-check form-check-solid form-switch fv-row">
            <input
              {...form.getFieldProps("is_public")}
              className={clsx("form-check-input w-45px h-30px")}
              type="checkbox"
              defaultChecked={content.is_public}
              disabled={form.isSubmitting}
            />
            <label className="form-check-label"></label>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <button
          type="reset"
          onClick={() => cancel()}
          className="btn btn-light me-3">
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary" disabled={loading}>
          {!loading && (<span className="indicator-label">Save</span>)}
          {loading && (
            <span className="indicator-progress d-block">
        Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
          )}
        </button>
      </div>
    </form>
  );
};