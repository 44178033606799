import { API_URI } from "../../../../constants/api";
import Req from "../../../../utils/request";


const API_URL = process.env.REACT_APP_API_URL;

const getDashBoard = async () => {
  try {
    const url = `${API_URL+API_URI.HASH_BOARD}/statistics`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};


export { getDashBoard }