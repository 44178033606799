import { useQuery } from "react-query";
import { useState } from "react";

import { useListView } from "../core/ListViewProvider";
import { isNotEmpty } from "../../../../../helpers";
import { getContentById } from "../core/_request";
import { Content } from "../core/_models";
import { ContentModalForm } from "./ContentModalForm";

const ContentModalFormWrapper = () => {

  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const [detailContent, setContentDetail] = useState<Content | null>();

  const {
    isLoading,
    error
  } = useQuery(
    `banner-${itemIdForUpdate}`,
    async () => {
      const [error, data] = await getContentById(itemIdForUpdate);
      if (!error && data) {
        return data;
      }
    }, {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
      onSuccess: (data: any) => {
        if (data) {
          setContentDetail(data);
        } else {
          setContentDetail(null);
        }
      }
    }
  );

  if (!itemIdForUpdate) {
    // @ts-ignore
    return <ContentModalForm content={{ id: undefined }} />;
  }
  if (!isLoading && !error && detailContent) {
    return <ContentModalForm content={detailContent} />;
  }
  return <span>Loading...</span>;
};

export { ContentModalFormWrapper };
