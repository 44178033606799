const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";

const getToken = (): any => {
  if (typeof window !== "undefined") {
    if (!window.localStorage) {
      return;
    }

    const lsValue: string | null = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
      return;
    }

    try {
      const auth: any = JSON.parse(lsValue) as any;
      if (!auth) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return auth;
    } catch (error) {
      console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
    }
  }
};

const setAuth = (auth: any) => {
  if (!window.localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (typeof window !== "undefined") {
    if (!window.localStorage) {
      return;
    }

    try {
      window.localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    } catch (error) {
      console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
    }
  }
};

export { getToken, setAuth, removeAuth };
