import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";

import { ProductsTable } from "./table/ProductsTable";
import { EditProductModal } from "./edit-modal/EditProductModal";
import { KTCard } from "helpers";
import { ProductsListHeader } from "./components/header/ProductsListHeader";

const ProductsList = () => {
  const { itemIdForUpdate } = useListView();

  return (
    <>
      <KTCard>
        <ProductsListHeader />
        <ProductsTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditProductModal />}
    </>
  );
};

const ProductsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProductsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { ProductsListWrapper };
