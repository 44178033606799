import { useQuery } from "react-query";

import { useListView } from "../core/ListViewProvider";
import { isNotEmpty } from "helpers";
import { getAdminById } from "../core/_requests";
import { AdminEditModalForm } from "./AdminEditModalForm";
import React from "react";

const AdminEditModalFormWrapper = () => {

  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);

  const {
    isLoading,
    data,
    error
  } = useQuery(
    `admin-${itemIdForUpdate}`,
    () => {
      return getAdminById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      }
    }
  );

  if (!itemIdForUpdate) {
    // @ts-ignore
    return <AdminEditModalForm isLoading={isLoading} admin={{ id: undefined }} />;
  }
  if (!isLoading && !error && data?.data) {
    return <AdminEditModalForm isLoading={isLoading} admin={data?.data} />;
  }
  return <span>Loading...{""}</span>;
};

export { AdminEditModalFormWrapper };
