import { KTSVG } from "../../../../../helpers";

function Rating({ rating = 5 }) {
  const ratingStart = Array.from(Array(rating));
  return (
    <div className="flex items-center">
      {ratingStart.fill(
        <KTSVG
          path="/media/icons/duotune/general/gen003.svg"
          className="text-danger"
        />
      )}
    </div>
  );
}

export default Rating;