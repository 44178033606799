import { KTSVG } from "helpers";
import { useListView } from "../../core/ListViewProvider";

const ReviewListToolbar = () => {
  const { setItemIdForUpdate } = useListView();

  const openAddModal = () => {
    setItemIdForUpdate(null);
  };

  return (
    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
      <button type="button" className="btn btn-primary" onClick={openAddModal}>
        <KTSVG path="/media/icons/duotune/arrows/arr075.svg" className="svg-icon-2" />
        Add Review
      </button>
    </div>
  );
};

export { ReviewListToolbar };
