import React, { useEffect, useState } from "react";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { MenuComponent } from "../../../../../../assets/ts/components";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { ReviewApproved } from "../../core/_models";

const ReviewListFilter = () => {
  const { state, updateState } = useQueryRequest();
  const [approve, setApprove] = useState<ReviewApproved>();
  const { refetch } = useQueryResponse();

  useEffect(() => {
    MenuComponent.reinitialization();
    filterData()
  }, [approve]);

  const filterData = () => {
    updateState({ ...state, is_approved: approve})
    refetch();
  }

  return (
    <div>
      <label className=" col-form-label fw-bold fs-6">
        Filter Review
      </label>
      <div className="row w-200px">
        <div className="mb-10">
          <select
            className="form-select form-select-solid fw-bolder cursor-pointer"
            onChange={(e) => setApprove(e.target.value as ReviewApproved)}
            value={approve}>
            <option value="">All</option>
            <option value="true">APPROVED</option>
            <option value="false">NOT APPROVED</option>
          </select>
        </div>
      </div>
    </div>
  );
};
export { ReviewListFilter };