/* eslint-disable react/jsx-no-target-blank */
import { menuItems } from "app/constants/menu";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to={menuItems.DASHBOARD.route}
        icon={menuItems.DASHBOARD.icon}
        title={menuItems.DASHBOARD.label}
        fontIcon={menuItems.DASHBOARD.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.PRODUCT.route}
        icon={menuItems.PRODUCT.icon}
        title={menuItems.PRODUCT.label}
        fontIcon={menuItems.PRODUCT.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.REVIEW.route}
        icon={menuItems.REVIEW.icon}
        title={menuItems.REVIEW.label}
        fontIcon={menuItems.REVIEW.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.ORDER.route}
        icon={menuItems.ORDER.icon}
        title={menuItems.ORDER.label}
        fontIcon={menuItems.ORDER.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.CATEGORY.route}
        icon={menuItems.CATEGORY.icon}
        title={menuItems.CATEGORY.label}
        fontIcon={menuItems.CATEGORY.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.ADMIN.route}
        icon={menuItems.ADMIN.icon}
        title={menuItems.ADMIN.label}
        fontIcon={menuItems.ADMIN.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.USER.route}
        icon={menuItems.USER.icon}
        title={menuItems.USER.label}
        fontIcon={menuItems.USER.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.CONTENT.route}
        icon={menuItems.CONTENT.icon}
        title={menuItems.CONTENT.label}
        fontIcon={menuItems.CONTENT.fontIcon}
      />
      <AsideMenuItem
        to={menuItems.SETTING.route}
        icon={menuItems.SETTING.icon}
        title={menuItems.SETTING.label}
        fontIcon={menuItems.SETTING.fontIcon}
      />
      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
      <div className="menu-item">
        
      </div>
    </>
  );
}
