import * as Yup from "yup";
import { useFormik } from "formik";
import { requestForgotPassword } from "../core/_requests";
import { toastAlert } from "../../../utils/request/toastify";
import React, { useState } from "react";
import clsx from "clsx";
import { useQueryResponse } from "../../admin/admins-list/core/QueryResopnseProvider";
import { useNavigate } from "react-router-dom";

const initialValues = {
  password_hash: "",
  confirm_password: ""
};

const forgotPasswordSchema = Yup.object()
  .shape({
    password_hash: Yup.string()
      .trim()
      .min(8, "Minimum 8 symbols")
      .max(16, "Maximum 16 symbols")
      .required("Password can is required"),
    confirm_password: Yup.string()
      .trim()
      .min(8, "Minimum 8 symbols")
      .max(16, "Maximum 16 symbols")
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password_hash"), null], "Password Confirm must match Password")
  });

export function ForgotPasswordAdmin() {
  const [loading, setLoading] = useState(false);
  const { refetch } = useQueryResponse();
  const navigate = useNavigate();

  const activeCode = window.location.pathname.split('/').at(-1)

  const form = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      const loginBody = {
        password_hash: values.password_hash,
        confirm_password: values.confirm_password,
        user_role: "SYSTEM_ADMIN"
      };
      const [error, data] = await requestForgotPassword(loginBody,activeCode as string);
      if (!error && data) {
        toastAlert({
          type: "success",
          message: "Update Success!"
        });
        setLoading(false);
        refetch();
        navigate('/login')
      } else {
        toastAlert({
          type: "error",
          message: "Update Failed!"
        });
        setLoading(false);
        setSubmitting(false);
      }
    }
  });

  return (
    <>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={form.handleSubmit}>
        <div className="row mb-8">
          <label className="text-center">
            <h1>
              UPDATE NEW PASSWORD
            </h1>
          </label>
          <div className="fv-row mb-7">
            <label htmlFor="password_hash" className="required fw-bold fs-6 mb-2">Password</label>
            <input
              id="password_hash"
              placeholder="Password"
              {...form.getFieldProps("password_hash")}
              type="password"
              name="password_hash"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": !!form.errors.password_hash },
                { "is-valid": !!form.errors.password_hash }
              )}
              autoComplete="off"
            />
            {form.errors.password_hash && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{form.errors.password_hash}</span>
                </div>
              </div>
            )}
          </div>
          <div className="fv-row mb-7">
            <label htmlFor="confirm_password_hard" className="required fw-bold fs-6 mb-2">Confirm Password</label>
            <input
              id="confirm_password_hard"
              placeholder="Confirm Password"
              {...form.getFieldProps("confirm_password")}
              type="password"
              name="confirm_password"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": !!form.errors.confirm_password },
                { "is-valid": !!form.errors.confirm_password }
              )}
              autoComplete="off"
            />
            {form.errors.confirm_password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{form.errors.confirm_password}</span>
                </div>
              </div>
            )}
          </div>
          <button type="submit" className="btn btn-lg btn-primary mt-4">
            <span className="indicator-label">Save</span>
            {loading && (
              <span className="indicator-progress">
                Please wait...{""}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );

}