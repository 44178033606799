import { useQuery } from "react-query";
import { useListView } from "../core/ListViewProvider";
import { isNotEmpty } from "../../../../../helpers";
import { useState } from "react";
import { Banner } from "../core/_models";
import { getBannerById } from "../core/_request";
import { BannerModalForm } from "./Banner";

const BannerModalFormWrapper = () => {

  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
  const [detailBanner, setBannerDetail] = useState<Banner | null>();

  const {
    error
  } = useQuery(
    `banner-${itemIdForUpdate}`,
    async () => {
      const [error, data] = await getBannerById(itemIdForUpdate);
      if (!error && data) {
        return data;
      }
    }, {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
      onSuccess: (data) => {
        if (data) {
          setBannerDetail(data);
        } else {
          setBannerDetail(null);
        }
      }
    }
  );

  // @ts-ignore
  return <BannerModalForm banner={itemIdForUpdate ? detailBanner : {} } />;
};

export { BannerModalFormWrapper };
