import React, { useEffect, useState } from "react";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { useQueryResponse } from "../core/QueryResopnseProvider";
import { MenuComponent } from "../../../../../assets/ts/components";
import { ContentType } from "../core/_models";

const ContentListFilter = () => {
  const { state, updateState } = useQueryRequest();
  const [contentType, setContentType] = useState<ContentType>();
  const { refetch } = useQueryResponse();

  useEffect(() => {
    MenuComponent.reinitialization();
    filterData()
  }, [contentType]);

  const filterData = () => {
    updateState({ ...state, content_type: contentType})
    refetch();
  }

  return (
    <div>
      <label className=" col-form-label fw-bold fs-6">
        Filter Content
      </label>
      <div className="row w-200px">
        <div className="mb-10">
          <select
            className="form-select form-select-solid fw-bolder cursor-pointer"
            onChange={(e) => setContentType(e.target.value as ContentType)}
            value={contentType}>
            <option value="">All</option>
            <option value="NEWS">NEWS</option>
            <option value="FAQ">FAQ</option>
            <option value="GENERAL">GENERAL</option>
          </select>
        </div>
      </div>
    </div>
  );
};
export { ContentListFilter };