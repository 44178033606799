import { GroupDeleteItems } from "app/layout/components/GroupDeleteItems";
import { ListToolbar } from "./ListToolbar";
import { useListView } from "../core/ListViewProvider";
import { ContentListFilter } from "./ListFilter";

const ContentListHeader = () => {
  const { selected } = useListView();

  return (
    <div className="card-header border-0 pt-6">
      <ContentListFilter/>
      <div className="card-toolbar">
        {selected.length > 0 ? (
          <GroupDeleteItems selected={selected} />
        ) : (
          <>
            <ListToolbar/>
          </>
        )}
      </div>
    </div>
  );
};

export { ContentListHeader };
