import { Account } from './account/Account';
import { PageLink, PageTitle } from 'app/layout/core';
import { menuItems } from "../../constants/menu";

const accountsBreadCrumbs: PageLink[] = [
  {
    title: 'Account',
    path: menuItems.ACCOUNT.route,
    isSeparator: false,
    isActive: false,
  },
];

const AccountSettingsPage = () => {
  return (
    <div>
      <PageTitle breadcrumbs={accountsBreadCrumbs}>Account</PageTitle>
      <Account />
    </div>
  );
};

export default AccountSettingsPage;
