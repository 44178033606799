import { GroupDeleteItems } from "app/layout/components/GroupDeleteItems";
import { ListSearchComponent } from "app/layout/components/search/ListSearchComponent";
import { QUERIES } from "helpers";
import { useMutation, useQueryClient } from "react-query";
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { deleteSelectedProducts } from "../../core/_requests";
import { ProductListToolbar } from "./ProductListToolbar";

const ProductsListHeader = () => {
  const { state, updateState } = useQueryRequest();
  const { selected, clearSelected } = useListView();
  const queryClient = useQueryClient();
  const { query } = useQueryResponse();

  const deleteSelectedItems = useMutation(() => deleteSelectedProducts(selected), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`]);
      clearSelected();
    },
  });

  return (
    <div className="card-header border-0 pt-6">
      <ListSearchComponent placeholder="Search Product" updateState={updateState} state={state} />
      <div className="card-toolbar">
        {selected.length > 0 ? (
          <GroupDeleteItems selected={selected} onHandleDeleteSelectedItems={deleteSelectedItems} />
        ) : (
          <ProductListToolbar />
        )}
      </div>
    </div>
  );
};

export { ProductsListHeader };
