/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from "react";
import { PageTitle } from "../../layout/core";
import {
  MixedWidget2
} from "../../partials/widgets";
import { useQuery } from "react-query";
import { getDashBoard } from "./_modals/create-app-stepper/_reqeusts";
import { KTSVG } from "../../../helpers";

const DashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const {
    data,
    error
  } = useQuery(
    `get-list-dash-board`,
    () => {
      return getDashBoard();
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
      }
    }
  );


  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (!loading && !error && data) {
    // @ts-ignore
    return <MixedWidget2 className="card-xl-stretch mb-xl-8" dashboard={data}
    />;
  }
  return <KTSVG path="/media/icons/duotune/art/art015.svg" className="svg-icon-5tx" />;
};

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{"Sale Statistic"}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
;

