import { API_URI } from "../../../../constants/api";
import Req from "../../../../utils/request";
import revalidateClientStaticPage from "../../../../utils/request/revalidateClientStaticPage";
import { Setting } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const updateSetting = async (setting: any) => {
  try {
    const url = `${API_URL + API_URI.SETTING}`;
    const result = await Req.PUT(url, setting);
    if (result?.status === 200 && result?.data) {
      await revalidateClientStaticPage("/");
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getSetting = async () => {
  try {
    const url = `${API_URL + API_URI.SETTING}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

export { updateSetting, getSetting };