import { ID } from "../../../../../helpers";

export interface Content {
  id?: ID,
  title: string,
  post_content: string,
  description: string,
  content_type: ContentType,
  is_public: boolean,
  status?: string
  created_date?: string,
  slug?: string,
}

export type ContentType = "NEWS" | "FAQ" | "GENERAL";

export const contentTypeOptions = ["NEWS", "FAQ", "GENERAL"];
