import { KTSVG } from "helpers";
import { useListView } from "../core/ListViewProvider";

const ReviewModalHeader = () => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();

  return (
    <div className="modal-header">
      <h2 className="fw-bolder">{itemIdForUpdate ? "Update Review" : "Add Review"} </h2>
      <div className="btn btn-icon btn-sm btn-active-icon-primary"
           data-kt-users-modal-action="close"
           onClick={() => setItemIdForUpdate(undefined)}
           style={{ cursor: "pointer" }}>
        <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
      </div>
    </div>
  );
};

export { ReviewModalHeader };
