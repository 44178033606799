import { useFormik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";
import React, { useState } from "react";
import ImageVariant from "app/utils/images/imageVariant";
import { ProductVariant } from "app/modules/products/products-list/core/_models";
import { API_URI } from "app/constants/api";
import Req from "app/utils/request";


const variantSchema = Yup.object()
  .shape({
    title: Yup.string()
      .trim()
      .required("Title required"),
    price: Yup.number()
      .required("Price required"),
    image_url: Yup.string()
      .trim()
  });

type Props = {
  onHandleSubmit: (variant: ProductVariant) => any;
  variant?: any;
};

const AddVariantForm = ({
  onHandleSubmit,
  variant
}: Props) => {
  const [isInput, setIsInput] = useState(false);
  const [formImg, setFormImg] = useState<FormData>()

  const initialValues = {
    id: variant?.id,
    product_id: variant?.product_id || "",
    title: variant?.title || "",
    price: variant?.price || "",
    image_url: variant?.image_url || "",
    renewable_price: variant?.renewable_price || "",
    status: variant?.status,
    create_date: variant?.create_date,
    update_date: variant?.update_date,
  };

  const handleUploadImg = async () => {
    const url = API_URI.BASE_URI + API_URI.PRODUCT + API_URI.IMAGE;
    const response =  await Req.POST(url, formImg);
    if (response && response?.data) {
      return response?.data
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: variantSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (formImg?.get('file')) {
        const imgUrl = await handleUploadImg()
        onHandleSubmit({ ...values, image_url: imgUrl});
      } else {
        onHandleSubmit(values)
      }
      setSubmitting(false);
    }
  });

  return (
    <>
        <div className="fv-row mb-7" style={{ flex: 1 }}>
          <label htmlFor="title" className="required fw-bold fs-6 mb-2 cursor-pointer">Title</label>
          <input
            id="title"
            autoFocus={true}
            placeholder="Title"
            {...(form.getFieldProps("title") || variant?.title)}
            type="text"
            name="title"
            className={clsx(
              "form-control form-control-solid mb-3 mb-lg-0",
              { "is-invalid": form.touched.title && form.errors.title },
              { "is-valid": form.touched.title && !form.errors.title }
            )}
            autoComplete="off"

          />
          {form.touched.title && form.errors.title && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{form.errors.title}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-row mb-7" style={{ flex: 1 }}>
          <label htmlFor="upload-input" className="fw-bold fs-6 mb-2">Product Variant Image</label>
          <ImageVariant
            attributes={form.getFieldProps("image_url")}
            setIsInput={setIsInput}
            isInput={isInput}
            setFormImg={setFormImg}
          />
        </div>
        <div className="fv-row mb-7" style={{ flex: 1 }}>
          <label htmlFor="variant_price" className="required fw-bold fs-6 mb-2 cursor-pointer">Price</label>
          <input
            autoFocus={true}
            id="variant_price"
            placeholder="Price"
            {...(form.getFieldProps("price") || variant?.price)}
            type="text"
            name="price"
            className={clsx(
              "form-control form-control-solid mb-3 mb-lg-0",
              { "is-invalid": form.touched.price && form.errors.price },
              { "is-valid": form.touched.price && !form.errors.price }
            )}
            autoComplete="off"
          />
          {form.touched.price && form.errors.price && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{form.errors.price}</span>
              </div>
            </div>
          )}
        </div>


        <div className="fv-row mb-7" style={{ flex: 1 }}>
          <label htmlFor="variant_renewable_price" className="fw-bold fs-6 mb-2 cursor-pointer">Sale Price</label>
          <input
            autoFocus={true}
            id="variant_renewable_price"
            placeholder="Price"
            {...(form.getFieldProps("renewable_price") || variant?.renewable_price)}
            type="text"
            name="renewable_price"
            className={clsx(
              "form-control form-control-solid mb-3 mb-lg-0",
              { "is-invalid": form.touched.renewable_price && form.errors.renewable_price },
              { "is-valid": form.touched.renewable_price && !form.errors.renewable_price }
            )}
            autoComplete="off"
          />
          {form.touched.renewable_price && form.errors.renewable_price && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{form.errors.renewable_price}</span>
              </div>
            </div>
          )}
        </div>

        <button
          type="button"
          onClick={() => form.handleSubmit()}
          className="btn btn-primary w-100px">
          <span className="indicator-label">Save</span>
        </button>
    </>
  );
};

export default AddVariantForm;