/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect } from "react";
import { ID } from "../../../../../helpers";
import { MenuComponent } from "../../../../../assets/ts/components";
import { useListView } from "../../../../modules/products/products-list/core/ListViewProvider";

type Props = {
  id: ID;
  isDetail?: boolean,
  banner: any
}

const BannerImageCell: FC<Props> = ({
  id,
  isDetail,
  banner,
}) => {
  const { setItemIdForUpdate } = useListView();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };
  return (
      <div className="symbol-circle">
        <a className="menu-link px-3" onClick={openEditModal}>
          {banner? (
            <div className="symbol-label border border-gray-800 cursor-pointer">
              <img src={banner} alt={banner} className="w-100" />
            </div>
          ) : (
            <div
              className={clsx(
                "symbol-label fs-3",
                `bg-light-${banner}`,
                `text-${banner}`
              )}>
              {banner}
            </div>
          )}
        </a>
      </div>
  );
};

export { BannerImageCell };
