/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { initialQueryState, KTSVG, useDebounce } from "helpers";

interface PropsInterfaces {
  updateState: any;
  placeholder?: string;
  state: any;
  attributes?: any;
}

const ListSearchComponent = ({
  updateState,
  placeholder = "search...",
  state,
  attributes
}: PropsInterfaces) => {
  const [searchTerm, setSearchTerm] = useState<string>(state?.search_key || "");
  const debouncedSearchTerm = useDebounce(searchTerm, 150);

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ ...initialQueryState, search_key: debouncedSearchTerm });
      }
    },
    [debouncedSearchTerm],
  );

  return (
    <div className="card-title">
      <div className="d-flex align-items-center position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="search"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-250px ps-14"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          {...attributes}
        />
      </div>
    </div>
  );
};

export { ListSearchComponent };
