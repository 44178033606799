import {AdminListSearchComponent} from './AdminListSearchComponent'
import { useListView } from "../../core/ListViewProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { GroupDeleteItems } from 'app/layout/components/GroupDeleteItems';
import { AdminListToolbar } from "./AdminListToolbar";
const AdminListHeader = () => {
  const { state, updateState } = useQueryRequest();
  const { selected } = useListView();

  return (
    <div className='card-header border-0 pt-6'>
      <AdminListSearchComponent placeholder="Search Admin" updateState={updateState} state={state} />
      <div className='card-toolbar'>
        {selected.length > 0 ? (
          <GroupDeleteItems selected={selected} onHandleDeleteSelectedItems={''} />
        ) : (
          <AdminListToolbar />
        )}
      </div>
    </div>
  )
}

export {AdminListHeader}
