import { FC } from "react";
import { Approved, Review } from "../../../../modules/review/review-list/core/_models";

type Props = {
  approved?: Review | Approved | boolean;
};

const ReviewStatusCell: FC<Props> = ({ approved }) => {
  const onHandleClassName = (approved: Review | "APPROVED" | "NOT APPROVED" | boolean | undefined) => {
    switch (approved) {
      case "APPROVED":
        return "badge-light-success text-center";
      case "NOT APPROVED":
        return "badge-light-danger text-center";
      default:
        return "badge badge-light fw-bolder";
    }
  };

  return <div style={approved === "NOT APPROVED"? {fontSize: 12} : {} && approved === "APPROVED"? {fontSize: 13} : {}}
              className={`${onHandleClassName(approved)} d-flex justify-content-center w-100`}>{approved}</div>;
};

export default ReviewStatusCell;
