import { useQuery } from "react-query";

import { useListView } from "../core/ListViewProvider";
import { getProductById } from "../core/_requests";
import { isNotEmpty } from "helpers";
import { EditModalForm } from "./EditModalForm";
import { AddProductForm } from "./AddProductForm";


const EditModalFormWrapper = () => {

  const { itemIdForUpdate, setItemIdForUpdate } = useListView();

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);

  const {
    isLoading,
    data,
    error,
  } = useQuery(
    `product-${itemIdForUpdate}`,
    () => {
      return getProductById(itemIdForUpdate);
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
    },
  );

  if (!itemIdForUpdate) {
    // @ts-ignore
    return <AddProductForm isLoading={isLoading} product={{ id: undefined}}/>
  }
  if (!isLoading && !error && data?.data) {
    return <EditModalForm isLoading={isLoading} product={data?.data}/>;
  }
  return <span>Loading...</span>;
};

export { EditModalFormWrapper };
