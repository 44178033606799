import React, { useState } from "react";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ListViewProvider } from "./core/ListViewProvider";
import { SettingModalFormWrapper } from "./components/setting-modal/PointModalFormWrapper";
import { KTCard } from "../../../../helpers";

const GeneralList = () => {
  return (
    <>
      <div>
        <SettingModalFormWrapper />
      </div>
    </>
  );
};

const SettingListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <GeneralList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { SettingListWrapper };
