import { Column } from "react-table";
import React from "react";

import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResopnseProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";

import { CustomHeader } from "../../../../../layout/components/table/columns/CustomHeader";
import { TextCell } from "../../../../../layout/components/table/columns/TextCell";
import ProductStatusCell from "../../../../../layout/components/table/columns/ProductStatusCell";
import { ProductActionsCell } from "../../../../../layout/components/table/columns/ProductActionsCell";
import { ID } from "../../../../../../helpers";
import { toastAlert } from "../../../../../utils/request/toastify";
import { deleteContent } from "../../core/_request";
import { DateCell } from "../../../../../layout/components/table/columns/DateCell";

const onHandleDelete = async (contentId: ID) => {
  const [error, data] = await deleteContent(contentId);
  if (!error && data) {
    toastAlert({
      type: "success",
      message: "Delete Success!"
    });
  } else {
    toastAlert({
      type: "error",
      message: "Delete Failed!"
    });
  }
};

const contentColumns: ReadonlyArray<Column<any>> = [
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Title"
        className="min-w-150px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "title",
    Cell: ({ ...props }) =>  <TextCell text={props.data[props.row.index].title} />,
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Content type"
        className="min-w-150px mr-2"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "content_type",
    Cell: ({ ...props }) =>  <TextCell text={`${props.data[props.row.index].content_type}`} />,
  },
  {
    Header: ({ ...props }) => (
      <CustomHeader
        tableProps={props}
        title="Last Modified Date"
        className="min-w-125px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "created_date",
    Cell: ({ ...props }) => (
      <DateCell date={`${props.data[props.row.index]?.created_date}`} />
    )
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="status"
        className=" min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "status",
    Cell: ({ ...props }) =>
      (
        <ProductStatusCell status={props.data[props.row.index]?.is_public? "ACTIVE" : "INACTIVE"} />
      )
  },
  {
    Header: (props) => (
      <CustomHeader
        tableProps={props}
        title="Actions"
        className="text-end min-w-100px"
        state={useQueryRequest()?.state}
        updateState={useQueryRequest()?.updateState}
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => (
      <ProductActionsCell
        id={props.data[props.row.index].id}
        useListView={useListView}
        useQueryResponse={useQueryResponse}
        onHandleDelete={onHandleDelete}
      />
    ),
  },
];

export { contentColumns };
