import React from 'react'
import { Route, Routes, Outlet} from 'react-router-dom'
import { PageLink, PageTitle } from '../../layout/core'
import { AdminListWrapper } from './admins-list/adminList';
import { menuItems } from "../../constants/menu";

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Admin',
    path: menuItems.ADMIN.route,
    isSeparator: false,
    isActive: false,
  },
]

const AdminPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}/>
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Admin Management</PageTitle>
              <AdminListWrapper/>
            </>
          }
        />
    </Routes>
  );
};

export default AdminPage
