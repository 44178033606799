import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {AdminListHeader} from './components/header/AdminListHeader'
import { KTCard } from 'helpers'
import { AdminTable } from "./table/AdminTable";
import { QueryResponseProvider } from "./core/QueryResopnseProvider";
import { AdminEditModal } from "./admin-edit-modal/AdminEditModal";


const AdminsList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <AdminListHeader />
        <AdminTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <AdminEditModal/>}
    </>
  )
}

const AdminListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AdminsList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AdminListWrapper}
