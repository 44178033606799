import {FC} from 'react'
import moment from 'moment';

type Props = {
  number?: number
  className?: string
}

const NumberCell: FC<Props> = ({number,className}) => (
  <>
    <div className={`fw-bolder w-150px text-truncate ${className}`}>{number}</div>
  </>
)

export {NumberCell}
