import * as Yup from "yup";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import clsx from "clsx";

import { stringifyRequestQuery } from "helpers";
import { toastAlert } from "app/utils/request/toastify";

import { Admin } from "../core/_models";
import { createAdmin, updateAdmin } from "../core/_requests";
import { useListView } from "../core/ListViewProvider";
import { useQueryResponse } from "../core/QueryResopnseProvider";
import { useQueryRequest } from "../core/QueryRequestProvider";
import md5 from "md5";

type Props = {
  isLoading: boolean;
  admin: Admin;
};

const addAdminSchema = Yup.object()
  .shape({
    email: Yup.string()
      .trim()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .required("Email is required"),
    first_name: Yup.string()
      .trim()
      .min(1, "First name can not be empty")
      .required("First name is required"),
    last_name: Yup.string()
      .trim()
      .min(1, "Last Name can not be empty")
      .required("Last Name is required"),
    password_hard: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(16, "Maximum 16 symbols")
      .required("Password can is required"),
    confirm_password_hard: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(16, "Maximum 16 symbols")
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password_hard"), null], "Password Confirm must match Password")
  });

const editAdminSchema = Yup.object()
  .shape({
    email: Yup.string()
      .trim()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .required("Email is required"),
    first_name: Yup.string()
      .trim()
      .min(1, "First name can not be empty")
      .required("First name is required"),
    last_name: Yup.string()
      .trim()
      .min(1, "Last Name can not be empty")
      .required("Last Name is required")
  });

const AdminEditModalForm: FC<Props> = ({
  admin,
  isLoading
}) => {
  const {
    setItemIdForUpdate,
    itemIdForUpdate
  } = useListView();
  const { refetch } = useQueryResponse();
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  const [loading, setLoading] = useState(false);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const form = useFormik({
    initialValues: admin,
    validationSchema: !itemIdForUpdate ? addAdminSchema : editAdminSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        if (admin?.id) {
          const [error, data] = await updateAdmin(values);
          if (!error && data) {
            setLoading(false);
            toastAlert({
              type: "success",
              message: "Update Success!"
            });
            setItemIdForUpdate(undefined);
            refetch();
          } else {
            toastAlert({
              type: "error",
              message: "Update Failed!"
            });
          }
        } else {
          setLoading(true);
          const body = {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            password_hard: md5(values.password_hard as string),
            confirm_password_hard: md5(values.confirm_password_hard as string),
            is_public: values.is_public
          };
          const [error, data] = await createAdmin(body);
          if (!error && data) {
            setLoading(false);
            toastAlert({
              type: "success",
              message: "Create Success!"
            });
            setItemIdForUpdate(undefined);
            refetch();
          } else {
            toastAlert({
              type: "error",
              message: data
            });
          }
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        setLoading(false);
        cancel(true);
      }
    }
  });

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  return (
    <form id="kt_modal_add_user_form" className="form" onSubmit={form.handleSubmit} noValidate>
      <div className="d-flex flex-column scroll-y me-n7 pe-7">
        <div className="fv-row mb-7 d-flex align-items-center">
          <div className="fv-row mb-7" style={{
            paddingRight: 20,
            flex: 1
          }}>
            <label htmlFor="first_name" className="required fw-bold fs-6 mb-2">First Name</label>
            <input
              id="first_name"
              placeholder="First Name"
              {...form.getFieldProps("first_name")}
              type="text"
              name="first_name"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0",
                {
                  "is-invalid": form.touched.first_name && form.errors.first_name
                },
                {
                  "is-valid": form.touched.first_name && !form.errors.first_name
                }
              )}
              autoComplete="off"
              disabled={form.isSubmitting}
            />
            {form.touched.first_name && form.errors.first_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{form.errors.first_name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="fv-row mb-7" style={{
            paddingLeft: 20,
            flex: 1
          }}>
            <label htmlFor="last_name" className="required fw-bold fs-6 mb-2">Last Name</label>
            <input
              id="last_name"
              placeholder="Last Name"
              {...form.getFieldProps("last_name")}
              type="text"
              name="last_name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                {
                  "is-invalid": form.touched.last_name && form.errors.last_name
                },
                {
                  "is-valid": form.touched.last_name && !form.errors.last_name
                }
              )}
              autoComplete="off"
              disabled={form.isSubmitting || isLoading}
            />
            {form.touched.last_name && form.errors.last_name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{form.errors.last_name}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="fv-row mb-7 w-200">
          <label htmlFor="email" className="required fw-bold fs-6 mb-2">Email</label>
          <input
            id="email"
            placeholder="Email"
            {...form.getFieldProps("email")}
            type="text"
            name="email"
            className={clsx(
              "form-control form-control-solid mb-3 mb-lg-0",
              {
                "is-invalid": form.touched.email && form.errors.email
              },
              {
                "is-valid": form.touched.email && !form.errors.email
              }
            )}
            autoComplete="off"
            disabled={form.isSubmitting || isLoading}
          />
          {form.touched.email && form.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{form.errors.email}</span>
            </div>
          </div>
          )}

        </div>

        {!itemIdForUpdate &&
          <div className="fv-row mb-7 d-flex align-items-center">
            <div className="fv-row mb-7" style={{
              paddingRight: 20,
              flex: 1
            }}>
              <label htmlFor="password_hard" className="required fw-bold fs-6 mb-2">Password</label>
              <input
                id="password_hard"
                placeholder="Password"
                {...form.getFieldProps("password_hard")}
                type="password"
                name="password_hard"
                className={clsx("form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": form.touched.password_hard && form.errors.password_hard
                  },
                  {
                    "is-valid": form.touched.password_hard && !form.errors.password_hard
                  }
                )}
                autoComplete="off"
              />
              {form.touched.password_hard && form.errors.password_hard && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{form.errors.password_hard}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="fv-row mb-7" style={{
              paddingLeft: 20,
              flex: 1
            }}>
              <label htmlFor="confirm_password_hard" className="required fw-bold fs-6 mb-2">Confirm Password</label>
              <input
                id="confirm_password_hard"
                placeholder="Confirm Password"
                {...form.getFieldProps("confirm_password_hard")}
                type="password"
                name="confirm_password_hard"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0",
                  {
                    "is-invalid": form.touched.confirm_password_hard && form.errors.confirm_password_hard
                  },
                  {
                    "is-valid": form.touched.confirm_password_hard && !form.errors.confirm_password_hard
                  }
                )}
                autoComplete="off"
              />
              {form.touched.confirm_password_hard && form.errors.confirm_password_hard && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{form.errors.confirm_password_hard}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        <div className="row mb-7">
          <label htmlFor="is_public" className="fw-bold fs-6 mb-2">Active/InActive</label>
          <div className="col-lg-8 d-flex align-items-center">
            <div className="form-check form-check-solid form-switch fv-row">
              <input
                id="is_public"
                {...form.getFieldProps("is_public")}
                className={clsx("form-check-input w-45px h-30px")}
                type="checkbox"
                defaultChecked={admin.is_public}
                disabled={form.isSubmitting || isLoading}
              />
              <label className="form-check-label"></label>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3">
            Cancel
          </button>

          <button
            type="submit"
            className="btn btn-primary" disabled={loading}>
            {!loading && (
              <span className="indicator-label">
                  Save
                </span>
            )}
            {loading && (
              <span className="indicator-progress d-block">
              Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </div>

    </form>
  );
};

export { AdminEditModalForm };
