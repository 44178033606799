import {FC} from 'react'

type Props = {
  date?: string
  className?: string;
}

export function convertUTCDate(date: string, isShorten?: boolean,) {
  if (date) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() - newDate.getTimezoneOffset() / 60);
    return isShorten ? newDate.toLocaleDateString : newDate.toLocaleString();
  }
  return '';
}

const DateCell: FC<Props> = ({date,className}) => {
  const parentClassName = className ?? '';
  return (

  <div className={parentClassName}>
    {date && <div>{convertUTCDate(date)}</div>}
  </div>
  )

}


export {DateCell}
