/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "helpers";

import "./index.css";
import { Dashboard } from "../../../pages/dashboard/_modals/create-app-stepper/IAppModels";
import { NumberCell } from "../../../layout/components/table/columns/NumberCell";
import CurrencyFormat from "react-currency-format";

type Props = {
  className: string
  dashboard: Dashboard;
}

const MixedWidget2: React.FC<Props> = ({
  className,
  dashboard
}) => {

  return (
    <div className={`card ${className}`}>
      <div className="card-body p-0">
        <div className="card p-5">
          <div className="row g-0">
            <div className="col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7">
              <KTSVG
                path="/media/icons/duotune/ecommerce/ecm001.svg"
                className="svg-icon-3x svg-icon-warning d-block my-2"
              />
              <div className="d-flex ">
                <label className="text-warning fw-bold fs-6">Total Order: </label>
                <NumberCell className="text-warning fw-bold fs-6 mx-2" number={(dashboard as any)[1]?.total_order || "N/A"} />
              </div>
            </div>
            <div className="col bg-light-primary px-6 py-8 rounded-2 mb-7">
              <KTSVG
                path="/media/icons/duotune/communication/com017.svg"
                className="svg-icon-3x svg-icon-primary d-block my-2"
              />
              <div className="d-flex">
                <label className="text-primary fw-bold fs-6"> Total Users: </label>
                <NumberCell className="text-primary fw-bold fs-6 mx-2" number={(dashboard as any)[1]?.total_customer || "N/A"} />
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col bg-light-danger px-6 py-8 rounded-2 me-7">
              <KTSVG
                path="/media/icons/duotune/abstract/abs027.svg"
                className="svg-icon-3x svg-icon-danger d-block my-2"
              />
              <div className="d-flex">
                <label className="text-danger fw-bold fs-6"> Total Product: </label>
                <NumberCell className="text-danger fw-bold fs-6 mx-2" number={(dashboard as any)[1]?.total_product || "N/A"} />
              </div>
            </div>
            <div className="col bg-light-success px-6 py-8 rounded-2">
              <KTSVG
                className="svg-icon-3x svg-icon-success d-block my-2"
                path="/media/icons/duotune/communication/com015.svg"
              />
              <div className="d-flex">
                <label className="text-success fw-bold fs-6"> Total Sales Of Month: </label>
                <div className="text-success fw-bold fs-6 mx-2">
                  <CurrencyFormat
                    value={(dashboard as any)[1]?.total_sales || "N/A"}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={"VNĐ"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MixedWidget2 };
