import { API_URI } from "../../../../constants/api";
import Req from "../../../../utils/request";
import { Banner } from "./_models";
import { ID } from "../../../../../helpers";
import revalidateClientStaticPage from "../../../../utils/request/revalidateClientStaticPage";

const API_URL = process.env.REACT_APP_API_URL;

const createBanner = async (banner: Banner) => {
  try {
    const url = `${API_URL + API_URI.BANNER}`;
    const result = await Req.POST(url,banner);
    if (result?.status === 200 && result?.data) {
      await revalidateClientStaticPage('/')
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const updateBanner = async (banner: Banner) => {
  try {
    const url = `${API_URL + API_URI.BANNER}/${banner.id}`;
    const result = await Req.PUT(url,banner);
    if (result?.status === 200 && result?.data) {
      await revalidateClientStaticPage('/')
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getPageBanner = async (query?: string) => {
  try {
    const url = `${API_URL + API_URI.BANNER + API_URI.PUBLIC}?${query}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
};

const getBannerById = async (banner: ID)=>{
  try {
    const url = `${API_URL + API_URI.BANNER + API_URI.PUBLIC}/${banner}`;
    const result = await Req.GET(url);
    if (result?.status === 200 && result?.data) {
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
}
const deleteBanner = async (bannerId: ID)=>{
  try {
    const url = `${API_URL + API_URI.BANNER}/${bannerId}`;
    const result = await Req.DELETE(url);
    if (result?.status === 200 && result?.data) {
      await  revalidateClientStaticPage('/')
      return [false, result.data];
    }
    return [true, null];
  } catch (error) {
    return [true, `Server error, plz try again later!`];
  }
}

export { createBanner,getPageBanner,getBannerById,deleteBanner,updateBanner };