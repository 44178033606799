/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC, useEffect } from "react";
import { ID } from "../../../../../helpers";
import { MenuComponent } from "../../../../../assets/ts/components";
import { useListView } from "../../../../modules/products/products-list/core/ListViewProvider";

type Props = {
  id: ID;
  isDetail?: boolean,
  partner: any
}

const PartnerImageCell: FC<Props> = ({
  id,
  isDetail,
  partner
}) => {
  const { setItemIdForUpdate } = useListView();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  const openEditModal = () => {
    setItemIdForUpdate(id);
  };
  return (
    <div className="d-flex w-100px border border-gray-500 rounded-circle overflow-hidden">
      <a onClick={openEditModal}>
        {partner ? (
          <div className="cursor-pointer">
            <img src={partner} alt={partner} className="w-100px" />
          </div>
        ) : (
          <div
            className={clsx(
              "symbol-label fs-3",
              `bg-light-${partner}`,
              `text-${partner}`
            )}>
            {partner}
          </div>
        )}
      </a>
    </div>
  );
};

export { PartnerImageCell };
