import { useQuery } from "react-query";

import { useListView } from "../core/ListViewProvider";
import { isNotEmpty } from "helpers";
import { getReviewById } from "../core/_request";
import { ReviewModalForm } from "./ReviewModalForm";
import { useState } from "react";
import { Review } from "../core/_models";

const ReviewModalFormWrapper = () => {

  const {
    itemIdForUpdate,
    setItemIdForUpdate
  } = useListView();

  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);

  const [detailReview, setReviewDetail] = useState<Review | null>();

  const {
    isLoading,
    error
  } = useQuery(
    `review-${itemIdForUpdate}`,
    () => {
      return getReviewById(itemIdForUpdate);
    }, {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined);
        console.error(err);
      },
      onSuccess: (data) => {
        if (data?.data) {
          setReviewDetail(data.data);
        } else {
          setReviewDetail(null);
        }
      }
    }
  );

  if (!itemIdForUpdate) {
    // @ts-ignore
    return <ReviewModalForm review={{ id: undefined }} />;
  }
  if (!isLoading && !error && detailReview) {
    return <ReviewModalForm review={detailReview} />;
  }
  return <span>Loading...</span>;
};

export { ReviewModalFormWrapper };
