import React, { useEffect, useMemo, useState } from "react";

import { KTSVG, stringifyRequestQuery } from "../../../../../../helpers";
import styles from "./styles.module.scss";
import { useListView } from "../../../../review/review-list/core/ListViewProvider";
import { useQueryResponse } from "../../../../review/review-list/core/QueryResponseProvider";
import { useQueryRequest } from "../../../../review/review-list/core/QueryRequestProvider";
import { useFormik } from "formik";
import { toastAlert } from "../../../../../utils/request/toastify";
import { updateSetting } from "../../core/_request";
import { Setting } from "../../core/_models";

type Props = {
  setting: any
}

const PointModalForm = (Props: Props) => {
  const { setting } = Props;

  const {
    setItemIdForUpdate
  } = useListView();
  const { refetch } = useQueryResponse();
  const [loading, setLoading] = useState(false);
  const {
    state
  } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...setting,
      youtube_video: setting.youtube_video?.join("\n")
    },
    onSubmit: async (values: Setting, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      const listYoutubeVideo = values.youtube_video?.replaceAll(",", "")
        .split("\n");
      const body = {
        rate: values.rate,
        youtube_video: listYoutubeVideo,
        youtube_url: values.youtube_url,
        facebook_url: values.facebook_url,
        tik_tok_url: values.tik_tok_url
      };
      const [error, data] = await updateSetting(body);
      if (!error && data) {
        setLoading(false);
        toastAlert({
          type: "success",
          message: "Update Success!!!"
        });
        setItemIdForUpdate(undefined);
        refetch();
      } else {
        toastAlert({
          type: "error",
          message: "Update Error!!!"
        });
      }
      setSubmitting(true);
      cancel(true);
    }
  });

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [query, updatedQuery]);


  return (
    <form onSubmit={form.handleSubmit} noValidate className="form">
      <div>
        <div className="mt-5">
          <label className="d-flex align-items-start mt-5">
            <h3>
              Point Accumulation
            </h3>
          </label>
          <div className="separator separator-solid border border-dark" />
          <div className="d-flex align-items-start flex-column">
            <div className="card-header border-0 pt-6 flex-column">
              <label htmlFor="amount" className="d-flex align-items-start">
                <h4>
                  From Amount
                </h4>
              </label>
              <div className="d-flex justify-content-start">
                <input
                  id="amount"
                  placeholder="1000"
                  type="text"
                  {...form.getFieldProps("rate") || setting.rate}
                  name="rate"
                  className="form-control mb-3 mb-lg-0 w-250px"
                  autoComplete="off"
                />
                <div className="p-4 d-flex justify-content-between">
                  <h3>{"="}</h3>
                </div>
                <div className="p-4 d-flex justify-content-between">
                  {"1 point"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <label className="d-flex align-items-start mt-5">
            <h3>
              Social Network
            </h3>
          </label>
          <div className="separator separator-solid border border-dark" />
          <div className="d-flex align-items-start flex-column">
            <div className="card-header border-0 pt-6 flex-column">
              <div className="d-flex justify-content-start">
                <div>
                  <div className="d-flex mb-3">
                    <label htmlFor="facebook">
                      <KTSVG path="/media/icons/duotune/social/soc004.svg" svgClassName={styles.icon} />
                    </label>
                    <input
                      id="facebook"
                      placeholder="http://facebook.com/fanpage"
                      type="text"
                      {...form.getFieldProps("facebook_url")}
                      name="facebook_url"
                      className=" mx-3 form-control mb-3 mb-lg-0 w-400px"
                      autoComplete="off"
                    />
                  </div>
                  <div className="d-flex mt-3">
                    <label htmlFor="youtube">
                      <KTSVG path="/media/icons/duotune/social/soc007.svg" svgClassName={styles.icon} />
                    </label>
                    <input
                      id="youtube"
                      placeholder="http://youtube.com/channel"
                      type="text"
                      {...form.getFieldProps("youtube_url") || setting.youtube_url}
                      name="youtube_url"
                      className="mx-3 form-control mb-3 mb-lg-0 w-400px"
                      autoComplete="off"
                    />
                  </div>
                  <div className="d-flex mt-3">
                    <label htmlFor="tiktok">
                      <KTSVG path="/media/icons/duotune/social/soc008.svg" svgClassName={styles.icon} />
                    </label>
                    <input
                      id="tiktok"
                      placeholder="http://tiktok.com/@channel"
                      type="text"
                      {...form.getFieldProps("tik_tok_url") || setting.tik_tok_url}
                      name="tik_tok_url"
                      className="mx-3 form-control mb-3 mb-lg-0 w-400px"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <label htmlFor="youtube_link" className="d-flex align-items-start mt-5">
            <h3>
              Youtube Videos
            </h3>
          </label>
          <div className="separator separator-solid border border-dark" />
          <div className="d-flex align-items-start flex-column">
            <div className="card-header border-0 pt-6 flex-column">
              <div className="d-flex justify-content-start">
                <div className="mb-3">
                  <textarea id="youtube_link"
                            className=" mx-3 form-control mb-3 mb-lg-0 mb-5"
                            style={{
                              height: 150,
                              width: 500
                            }}
                            {...form.getFieldProps("youtube_video")}
                            name="youtube_video" />

                  <div className="px-3 form-text d-flex justify-content-start">
                    (input each video link in one line)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-start card-header border-0 pt-6 flex-column">
          <button type="submit" className="btn btn-primary w-150px" disabled={loading}>
            {!loading && "Save"}
            {loading && (
              <div className="indicator-progress d-block">
                Loading...{" "}
                <div className="spinner-border spinner-border-sm align-middle ms-2"></div>
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );

};

export { PointModalForm };


