
import React from "react";
import { BannerModalFormWrapper } from "./banner-modal/BannerModalFormWrapper";
import { KTCard } from "helpers";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ListViewProvider } from "./core/ListViewProvider";

const BannerList = () => {
  return (
    <>
      <KTCard>
        <BannerModalFormWrapper />
      </KTCard>
    </>
  );
};

const BannerListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <BannerList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { BannerListWrapper };