import React, { useState } from "react";
import { SettingListWrapper } from "./setting-list/GeneralList";
import { BannerListWrapper } from "../banner/banner-list/BannerList";
import { PartnerListWrapper } from "../partner/partner-list/PartnerList";

const SettingList = () => {
  const [tabName, setTabName] = useState("1");

  const tabList = [
    {
      id: "1",
      tabTitle: "General",
      content: <SettingListWrapper />
    },
    {
      id: "2",
      tabTitle: "Banner",
      content: <BannerListWrapper />
    },
    {
      id: "3",
      tabTitle: "Partner",
      content: <PartnerListWrapper />
    }

  ];

  return (
    <>
      <div>
        {tabList.map(tab => (
          <div
            id={tab.id}
            key={tab.id}
            className={`mb-2 btn cursor-pointer${tabName === tab.id ? " rounded-0 font-bold border-dark border-bottom" : ""}`}
            onClick={() => setTabName(tab.id)}>
            {tab.tabTitle}
          </div>
        ))}
      </div>
      {tabList.map(
        tab =>
          tabName === tab.id && (
            <div key={tab.id} className="mx-auto text-center">
              {tab.content}
            </div>
          )
      )}
    </>
  );
};

export { SettingList };

