import clsx from "clsx";
import React, { useEffect, useState } from "react";

import styles from "./uploadAvt.module.scss";
import { toAbsoluteUrl } from "helpers/AssetHelpers";
import { KTSVG } from "helpers";
import { updateAvatarAccount } from "../core/_request";

interface UploadAvatarProps {
  image?: any;
  field?: string;
  setImage?: (field: string, avatar: any) => void;
}

const UploadAvatar = (props: UploadAvatarProps) => {
  const {
    image,
    field,
    setImage
  } = props;
  const imgInit = toAbsoluteUrl("/media/avatars/blank.png");

  const [img, setImg] = useState(image || imgInit);
  const [isLoading, setIsLoading] = useState(false);

  const handleAvtChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      try {
        setIsLoading(true);
        const result = await updateAvatarAccount(e.target.files[0]);
        const [isError, data] = result;
        if (!isError && data) {
          setImage?.(field || "avatar", data);
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      } catch (error) {
        console.log(
          "~ file:account-setting/UploadAvatar.tsx ~ line 29 ~ handleAvtChange ~ error",
          error
        );
      }
      let reader = new FileReader();
      reader.onload = function(e) {
        setImg(e?.target?.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    setImg(image);
  }, [image]);

  return (
    <div className={clsx(styles.formImageWrap)}>
      <label htmlFor="upload-avatar" className="cursor-pointer">
        <img
          src={img || imgInit}
          alt=""
          width="100%"
          height="100%"
          className={clsx("position-absolute top-0 start-0", styles.objectFitCover)}
        />
      </label>

      <input
        id="upload-avatar"
        className={clsx(styles.fromUploadFile, "ms-20")}
        type="file"
        name={field}
        onChange={handleAvtChange}
        accept=".png,.pjp,.jpg,.jpeg,.jfif,.pjpeg,.ico"
        hidden
      />
      {isLoading && (
        <div
          className={clsx(
            "position-absolute top-0 start-0 bottom-0 w-100 d-flex align-items-center justify-content-center",
            styles.overlayAvatar
          )}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      )}
    </div>
  );
};

export default UploadAvatar;
