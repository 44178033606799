import axios from "axios";
import { getToken } from "helpers/AuthHelpers";
import QueryString from "qs";

const token = getToken()?.api_token;

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    credentials: false,
    "content-type": "application/json"
  },
  paramsSerializer: (params) => QueryString.stringify(params)
});

axiosClient.interceptors.request.use(async (config) => {

  if (token) {
    config.headers["Auth-Token"] = token;
  }
  return config;

});

axiosClient.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        ...config.headers
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  async (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    throw error;
  }
);

const GET = async (url: string, config?: any) => await axiosClient.get(url, config);

const POST = async (url: string, body?: any) => await axiosClient.post(url, body);

const PUT = async (url: string, body?: any) => await axiosClient.put(url, body);

const DELETE = async (url: string, body?: any) => await axiosClient.delete(url, body);

const PATCH = async (url: string, body?: any) => await axiosClient.patch(url, body);

const Req = {
  GET,
  POST,
  PUT,
  DELETE,
  PATCH
};

export default Req;
