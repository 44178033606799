import { ProductVariant } from "../../../modules/products/products-list/core/_models";

export type SelectBoxArrayValues = Array<{ value: string | number | undefined; label: string }> | undefined;

type Props = {
  values: SelectBoxArrayValues;
  label?: string;
  attributes?: any;
  error?: string;
  className?: string;
  required?: boolean;
  display?: string;
  disabled?: boolean
};

const SelectBox = ({
  values,
  label,
  attributes,
  error,
  className,
  required,
  display,
  disabled = false
}: Props) => {
  const parentClassName = className ?? "";
  return (
    <div className={parentClassName}>
      {
        label && (
          <label className="col-lg-4 col-form-label fw-bold fs-6">
            <span className={required ? "required" : ""}>{label}</span>
          </label>
        )
      }
      <div>
        <select className="form-select form-select-solid form-select-lg fw-bold cursor-pointer" {...attributes} disabled={disabled}>
          <option value="">Select Options...</option>
          {values?.map((value) => (
            <option value={value?.value} key={value.value}>
              {value.label}
            </option>
          ))}
        </select>
        {!!error && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{error}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectBox;
