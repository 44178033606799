import { toAbsoluteUrl } from 'helpers/AssetHelpers';
import { ID } from 'helpers/crud-helper/models';

export interface IAccount {
  id?: ID;
  avatar?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password_hash: string;
  confirm_password: string;
  admin_id?: ID | undefined;
  is_public?: boolean;
}
export interface IPasswordAccount {
  passwordHard?: string;
  confirmPasswordHard?: string;
}


export const defaultAccount: IAccount = {
  avatar: toAbsoluteUrl('/media/avatars/blank.png'),
  first_name: '',
  last_name: '',
  email: '',
  password_hash: '',
  confirm_password: '',
  id: '',
  admin_id: '',
  is_public: true
};
