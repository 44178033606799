import { useQueryRequest } from "../../core/QueryRequestProvider";
import { UsersListSearchComponent } from "./UsersListSearchComponent";

const UsersListHeader = () => {

  const {
    state,
    updateState
  } = useQueryRequest();

  return (
    <div className="card-header border-0 pt-6">
      <UsersListSearchComponent
        placeholder="Search User"
        updateState={updateState}
        state={state}
      />
      <div className="card-toolbar" />
    </div>
  );
};

export { UsersListHeader };

