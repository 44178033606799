import {FC} from 'react'
import moment from 'moment';

type Props = {
  text?: string
  className?: string
}

const TextCell: FC<Props> = ({text,className}) => (
  <>
    <div className={`fw-bolder w-150px text-truncate ${className}`}>{text}</div>
  </>
)

export {TextCell}
