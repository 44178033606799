import { FC } from "react";
import { OrderStatus, Status } from "../../../../modules/order-manager/order-list/core/_models";

type Props = {
  status?: OrderStatus | Status | string;
};

const ProductStatusCell: FC<Props> = ({ status }) => {
  const onHandleClassName = (status: string | undefined) => {
    switch (status) {
      case "ACTIVE":
        return "badge-light-success text-center";
      case "DELIVERED":
        return "badge-light-primary text-center";
      case "CONFIRMED":
        return "badge-light-warning text-center";
      case "PAID":
        return "badge-light-success text-center";
      case "PENDING":
        return "badge-light-danger text-center";
      case "CANCELLED":
        return "badge-light-grey text-center";
      case "INACTIVE":
        return "badge-light-danger text-center";
      default:
        return "badge badge-light fw-bolder";
    }
  };

  return <div className={`${onHandleClassName(status)} d-flex justify-content-center w-full`}>{status}</div>;
};

export default ProductStatusCell;
